import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { LandingComponent } from './landing/landing.component';
import { AuthGuardService as AuthGuard } from './services/auth/auth-guard.service';
import { ViewOrdersComponent } from './pages/view-orders/view-orders.component';
import { ViewOrderDetailComponent } from './pages/view-order-detail/view-order-detail.component';
import { CustomTransactionComponent } from './pages/custom-transaction/custom-transaction.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { MyOrdersComponent } from './pages/my-orders/my-orders.component';
import { SubmitRequestComponent } from './pages/submit-request/submit-request.component';
import { CompleteOrderComponent } from './pages/complete-order/complete-order.component';
import { CustomOrderComponent } from './pages/custom-order/custom-order.component';
import { CustomPMRequestComponent } from './pages/custom-pmrequest/custom-pmrequest.component';
import { ViewRequestsComponent } from './pages/view-requests/view-requests.component';
import { ViewCompletedOrdersComponent } from './pages/view-completed-orders/view-completed-orders.component';

const routes: Routes = [
    { path: 'dashboard',                      component: DashboardComponent,          canActivate: [AuthGuard] },
    { path: 'user-profile',                   component: UserProfileComponent,        canActivate: [AuthGuard] },
    { path: 'orders',                         component: ViewOrdersComponent,         canActivate: [AuthGuard] },
    { path: 'my-orders',                      component: ViewCompletedOrdersComponent,canActivate: [AuthGuard] },
    { path: 'pm-requests',                    component: ViewRequestsComponent,       canActivate: [AuthGuard] },
    { path: 'pm-requests/view/:orderId',      component: ViewOrderDetailComponent,    canActivate: [AuthGuard] },
    { path: 'my-orders',                      component: MyOrdersComponent,           canActivate: [AuthGuard] },
    { path: 'orders/view/:orderId',           component: ViewOrderDetailComponent,    canActivate: [AuthGuard] },
    { path: 'orders/complete/:key/:orderId',  component: CompleteOrderComponent,      canActivate: [AuthGuard] },
    { path: 'customTransaction',              component: CustomTransactionComponent,  canActivate: [AuthGuard] },
    { path: 'customTransaction/order',        component: CustomOrderComponent,        canActivate: [AuthGuard] },
    { path: 'customTransaction/pm-request',   component: CustomPMRequestComponent,    canActivate: [AuthGuard] },
    { path: 'submitRequest',                  component: SubmitRequestComponent,      canActivate: [AuthGuard] },
    { path: 'unauthorized',                   component: UnauthorizedComponent },
    { path: '',                               component: LandingComponent },
    { path: '**',                             redirectTo: ''}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
