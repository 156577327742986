import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { environment } from 'environments/environment';

@Injectable()

export class NotificationService {
  readonly VAPID_PUBLIC_KEY = environment.vapid_public_key;

  constructor(private swPush: SwPush) { }

  subscribeToNotifications() {
    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    })
    .then( sub => this.addSubscriber(sub))
    .catch(err => console.log('Unableto subscribe to notifications:\n', err));
  }

  private addSubscriber(subscriber) {
    console.log(JSON.parse(subscriber));
  }


}
