import { Component, OnInit, Inject } from '@angular/core';
import * as Chartist from 'chartist';
import { AuthService } from '../services/auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileService } from 'app/services/profile/profile.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  orders = 0;
  role;
  isSuperAdmin = false;
  isDriver = false;
  isPropertyManager = false;
  isProfileComplete;

  constructor( public authService: AuthService, private router: Router, private profileService: ProfileService, public dialog: MatDialog) {
    // this if statement is needed due to a unintended behavior in Auth0
    // when a user logs in isAuthenticated() returns false until all auth0 code executes
    // authService.readyStatic checks to see if auth0 has finsihed executing, if false, subscribe and wait till complete
    if (this.authService.readyStatic) {
      if (this.authService.isAuthenticated()) {
        this.role = this.authService.getRole();
        this.setControls();
        this.checkProfileComplete();
      }
    } else {
      this.authService.ready.subscribe(ready => {
        this.role = this.authService.getRole();
        this.setControls();
        this.checkProfileComplete();
      })
    }
  }

  ngOnInit() { }

  // sets controls for the page based on the role of the user
  setControls() {
    if (this.role === 'super-admin') {
      this.isSuperAdmin = true;
      this.isDriver = false;
      this.isPropertyManager = false;
    } else if (this.role === 'driver') {
      this.isSuperAdmin = false;
      this.isDriver = true;
      this.isPropertyManager = false;
    } else if (this.role === 'property-manager') {
      this.isSuperAdmin = false;
      this.isDriver = false;
      this.isPropertyManager = true;
    }
  }

  // this function is used to check if the user has completed their profile
  // if not, then display pop up to navigate user to profile page
  checkProfileComplete() {
    if (this.profileService.localUserProfile) {
      if (this.profileService.localUserProfile.user_metadata) {
        this.isProfileComplete = this.profileService.localUserProfile.user_metadata.profile_complete;
      } else {
        this.isProfileComplete = false;
      }
      if (!this.isProfileComplete) {
        this.openWelcomeDialog(this.profileService.localUserProfile.user_metadata.first_name);
      }
    }
    this.profileService.userProfile.subscribe(profile => {
      if (profile.user_metadata) {
        this.isProfileComplete = profile.user_metadata.profile_complete;
      } else {
        this.isProfileComplete = false;
      }
      if (!this.isProfileComplete) {
        this.openWelcomeDialog(profile.user_metadata.first_name);
      }
    });
  }

  openWelcomeDialog(name): void {
      const dialogRef = this.dialog.open(FirstTimeWelcomeDialogComponent, {
        width: 'auto',
        data: {name: name}
      });
  }

  startAnimationForLineChart(chart) {
      let seq: any, delays: any, durations: any;
      seq = 0;
      delays = 80;
      durations = 500;

      chart.on('draw', function(data) {
        if (data.type === 'line' || data.type === 'area') {
          data.element.animate({
            d: {
              begin: 600,
              dur: 700,
              from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
              to: data.path.clone().stringify(),
              easing: Chartist.Svg.Easing.easeOutQuint
            }
          });
        } else if (data.type === 'point') {
              seq++;
              data.element.animate({
                opacity: {
                  begin: seq * delays,
                  dur: durations,
                  from: 0,
                  to: 1,
                  easing: 'ease'
                }
              });
          }
      });

      seq = 0;
  };
  startAnimationForBarChart(chart) {
      let seq2: any, delays2: any, durations2: any;

      seq2 = 0;
      delays2 = 80;
      durations2 = 500;
      chart.on('draw', function(data) {
        if (data.type === 'bar') {
            seq2++;
            data.element.animate({
              opacity: {
                begin: seq2 * delays2,
                dur: durations2,
                from: 0,
                to: 1,
                easing: 'ease'
              }
            });
        }
      });

      seq2 = 0;
  };
  setUpGraphs() {
      /* ----------==========     Daily Sales Chart initialization For Documentation    ==========---------- */

      const dataDailySalesChart: any = {
          labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
          series: [
              [12, 17, 7, 17, 23, 18, 38]
          ]
      };

     const optionsDailySalesChart: any = {
          lineSmooth: Chartist.Interpolation.cardinal({
              tension: 0
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: { top: 0, right: 0, bottom: 0, left: 0},
      };

      const dailySalesChart = new Chartist.Line('#dailySalesChart', dataDailySalesChart, optionsDailySalesChart);

      this.startAnimationForLineChart(dailySalesChart);


      /* ----------==========     Completed Tasks Chart initialization    ==========---------- */

      const dataCompletedTasksChart: any = {
          labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
          series: [
              [230, 750, 450, 300, 280, 240, 200, 190]
          ]
      };

     const optionsCompletedTasksChart: any = {
          lineSmooth: Chartist.Interpolation.cardinal({
              tension: 0
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: { top: 0, right: 0, bottom: 0, left: 0}
      };

      const completedTasksChart = new Chartist.Line('#completedTasksChart', dataCompletedTasksChart, optionsCompletedTasksChart);

      // start animation for the Completed Tasks Chart - Line Chart
      this.startAnimationForLineChart(completedTasksChart);



      /* ----------==========     Emails Subscription Chart initialization    ==========---------- */

      const dataEmailsSubscriptionChart = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [
          [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]

        ]
      };
      const optionsEmailsSubscriptionChart = {
          axisX: {
              showGrid: false
          },
          low: 0,
          high: 1000,
          chartPadding: { top: 0, right: 5, bottom: 0, left: 0}
      };
      const responsiveOptions: any[] = [
        ['screen and (max-width: 640px)', {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function (value) {
              return value[0];
            }
          }
        }]
      ];
      const emailsSubscriptionChart = new Chartist.Bar('#emailsSubscriptionChart', dataEmailsSubscriptionChart,
                                        optionsEmailsSubscriptionChart, responsiveOptions);

      // start animation for the Emails Subscription Chart
      this.startAnimationForBarChart(emailsSubscriptionChart);
  }

}

@Component({
  selector: 'app-first-time-welcome-dialog',
  templateUrl: 'welcome/first-time-welcome-dialog.html'
})
export class  FirstTimeWelcomeDialogComponent {
  constructor (
    public dialogRef: MatDialogRef<FirstTimeWelcomeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private router: Router) {
      dialogRef.disableClose = true;
    }

    onClose(): void {
      this.dialogRef.close();
      this.router.navigate(['/user-profile']);

    }

}
