import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// RxJS Imports
import { Subscription } from 'rxjs';

// Service Imports
import { AuthService } from '../services/auth/auth.service';
import { ProfileService } from '../services/profile/profile.service';
import { NotificationService } from 'app/services/notification/notification.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  // control variables
  userRole: string;
  isSuperAdmin = false;
  isDriver = false;
  isPropertyManager = false;
  isAuthorized = false;
  
  isEditable = false;
  profile: any;
  edit_mode = false;
  subscription: Subscription;
  profile_complete = false;
  profile_validated = false;
  btn_text = 'Edit Profile';
  confirm_text = 'Confirm Profile';
  update_text = 'Update Profile';
  confirm_profile_notice = 'Thank you for choosing HaulShare. Please Take a minute to complete your profile.'
  showErrorMessage = false;

  constructor(public authService: AuthService, private profileService: ProfileService, private router: Router,
                private notificationService: NotificationService) {
    // this if statement is needed due to a unintended behavior in Auth0
    // when a user logs in isAuthenticated() returns false until all auth0 code executes
    // authService.readyStatic checks to see if auth0 has finsihed executing, if false, subscribe and wait till complete
    if (this.authService.readyStatic) {
      if (this.authService.isAuthenticated()) {
        this.userRole = this.authService.getRole();
        this.setControls();
        this.checkPermission();
      }
    } else {
      this.authService.ready.subscribe(ready => {
        this.userRole = this.authService.getRole();
        this.setControls();
        this.checkPermission();
      });
    }
  }

  ngOnInit() {
  }

  setControls() {
    if (this.userRole === 'super-admin') {
      this.isSuperAdmin = true;
      this.isDriver = false;
      this.isPropertyManager = false;
    } else if (this.userRole === 'driver') {
      this.isSuperAdmin = false;
      this.isDriver = true;
      this.isPropertyManager = false;
    } else if (this.userRole === 'property-manager') {
      this.isSuperAdmin = false;
      this.isDriver = false;
      this.isPropertyManager = true;
      this.isAuthorized = true;
    } else {
      this.router.navigate( ['/dashboard'] );
    }

    if(this.userRole && this.userRole !== '') {
      this.isAuthorized = true;
    }

  }

  checkPermission() {
    if(this.authService.isAuthenticated()) {
      this.isAuthorized = true;
      this.isEditable = true;
      this.loadProfile();
    } else {
      this.router.navigateByUrl('/unauthorized');
    }
  }

  loadProfile() {
    if (this.profileService.localUserProfile) {
      this.profile = this.profileService.localUserProfile;
      this.setProfileComplete();
    } else {
      this.subscription = this.profileService.getProfile().subscribe(profile => {
        this.profile = profile;
        this.setProfileComplete();
      }, err => {
        console.log(err)
      });
    }
  }

  editProfile() {
    this.edit_mode = true;
  }

  setProfileComplete(): void {
    if (this.profile.user_metadata.address && this.profile.user_metadata.first_name &&
          this.profile.user_metadata.last_name && this.profile.user_metadata.phone_number
          && this.profile.user_metadata.company ) {
        // local variable to make sure all the proper profile fields have been defined during account creation
            this.profile_complete = true;
            // local variable to make sure the user has validated information
            if (this.profile.user_metadata.profile_complete) {
              this.profile_validated = true;
            } else {
              this.btn_text = 'Continue';
            }
    }
  }

  submitForm() {
    this.edit_mode = false;
    this.profile.user_metadata.profile_complete = true;
    this.profileService.updateProfile(this.profile).subscribe(response => {
      console.log(response);
      this.showErrorMessage = false;
      this.btn_text = 'Edit Profile';
      this.profile_validated = true;
    }, error => {
      console.log(error);
      this.showErrorMessage = true;
    });
  }

  enableNotifications() {
    this.notificationService.subscribeToNotifications();
  }

}
