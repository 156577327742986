import { Injectable, OnInit } from '@angular/core';

// RxJS stuff

import { Observable, Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { HttpService } from '../http/http.service';
import { AuthService } from '../auth/auth.service';
import { Role } from 'app/models/Role';

@Injectable()
export class ProfileService {
    private api_url = environment.backendurl;
    public userProfile = new Subject<any>();
    public localUserProfile: any;
    public userRole = new Subject<Role>();
    public localUserRole: Role;

    constructor( private http: HttpService, private authService: AuthService) {
        if (this.authService.isAuthenticated()) {
            this.setProfile();
        }
    }

    // Set user profile
    public setProfile(): void {
        this.retrieveProfile().subscribe(profile => {
            this.localUserProfile = profile;
            this.userProfile.next(this.localUserProfile);
        })

    }

    // Get user profile from backend/Auth0
    private retrieveProfile(): Observable<any> {
        const accessToken = localStorage.getItem('access_token');

        if (this.authService.isAuthenticated()) {
            // throw new Error('Must be logged in to view profile.');
        }

        const user_id = localStorage.getItem('user_id');

        return this.http.get(this.api_url + '/user/');

    }

    // Return locally saved userProfile
    public getProfile() {
        if (!this.localUserProfile) {
            this.setProfile();
        }
        return this.userProfile.asObservable();
    }

    // update userProfile
    public updateProfile(profile: any): Observable<any> {
        const accessToken = localStorage.getItem('access_token');

        if (!this.authService.isAuthenticated()) {
            throw new Error('Must be logged in to edit profile.');
        }

        const user_id = localStorage.getItem('user_id');
        this.localUserProfile = profile;
        this.localUserProfile.user_metadata = profile.user_metadata;

        return this.http.post(this.api_url + '/user/', this.localUserProfile.user_metadata);


    }

    // functions to help with custom pm requests - retrieve information about other users
    public getListOfPMs(): Observable<any> {
        if (!this.authService.isAuthenticated()) {
            throw new Error('Must be logged in to view list of PMs');
        }

        return this.http.get(this.api_url + '/user/getAllPMs');
    }

    public getListOfHaulers(): Observable<any> {
        if (!this.authService.isAuthenticated()) {
            throw new Error('Must be logged in to view list of Haulers');
        }

        return this.http.get(this.api_url + '/user/getAllHaulers');
    }

    public getPMProfile(user_id): Observable<any> {
        if (!this.authService.isAuthenticated()) {
            throw new Error('Must be logged in to Retrieve PM Info.');
        }

        return this.http.get(this.api_url + '/user/' + user_id)
    }
}
