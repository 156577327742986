import { Injectable } from '@angular/core';
import { OrderService } from '../order/order.service';

@Injectable({
  providedIn: 'root'
})

// helper class to extrapolate and format text from order object
export class StringUtilsService {

  constructor() { }
    // helper function to format address
    formatAddressString(order) {
      let returnVal: string;
      let address: any;
      
      // for pm_requests, address is stored under: order.pm_request.address
      // for prepaid_customer_request, address is stored under: order.prepaid_request.address
      switch (order.order_type) {
        case "prepaid_customer_order":
          address = order.prepaid_request ? order.prepaid_request.address : null;
          break;
        case "pm_request":
          address = order.pm_request ? order.pm_request.address : null;
          break;
        default: {
          address = null;
        }
      }
      if (address) {
        if (address.address_line2 && address.address_line2.length > 0) {
          returnVal = address.address_line1 + ' ' + address.address_line2 + ' ' + address.city +
                          ', ' + address.state + ', ' + address.postal_code;
        } else {
          returnVal = address.address_line1 + ' ' + address.city +
                          ', ' + address.state + ', ' + address.postal_code;
        }
      }

      return returnVal;
    }

    // helper function to format order type
    formatOrderType(order): string {
      let orderType = '';
      switch(order.order_type){
        // if order type is prepaid_customer_order, check to see if they have curbside_discount applied
        case "prepaid_customer_order":
          orderType = this.getCurbsideInfo(order.prepaid_request);
          break;
        // if order type is pm_request, simply return PM 'Request'
        case "pm_request":
          orderType = "Custom PM Request";
          break;
      }

      return orderType;
    }

    // private function to get information related to curbside discount
    private getCurbsideInfo(orderInfo) {
      let returnVal;
      // check to see if it is a custom prepaid order
      if (orderInfo.custom_prepaid_order) {
        returnVal = 'Custom Order';
      } else {
        // check to see if the customer checked the curbside option during checkout
        if (orderInfo.cart.curbside_discount) {
          returnVal = 'Curbside';
        } else {
          returnVal = 'In-home';
        }
      }
      
      return returnVal;
    }

    // helper function to format time info
    formatPickupTime(order): string {
      let pickupTime;
      switch(order.order_type){
        // switch case to access pickup_info in different parts of the object
        case "prepaid_customer_order":
          pickupTime = order.prepaid_request ? this.getPickupInfo(order.prepaid_request.pickup_info) : null;
          break;
        case "pm_request":
          pickupTime = order.pm_request ? this.getPickupInfo(order.pm_request.pickup_info) : null;
          break;
      }
      return pickupTime;
    }
    
    // helper function to get pickup time from order info
    private getPickupInfo(pickupInfo) {
      let pickupTime = '';
      if (pickupInfo) {
        if (pickupInfo.timeAny) {
          pickupTime = 'Anytime';
        } else {
          if (pickupInfo.timeFrom === pickupInfo.timeTo) {
            pickupTime = 'See Order Details';
          } else {
            pickupTime = 'Between ' + pickupInfo.timeFrom + ' - ' + pickupInfo.timeTo;
          }
        }
      } else {
        pickupTime = 'See Order Details';
      }
  
      return pickupTime;
      
    }

    // helper function to construct the string that displays order details
    formatOrderDetails(order): string {
      let orderDetails = '';
      switch(order.order_type){
        // if order type is prepaid_customer_order, use helper function to parse through order details
        case "prepaid_customer_order":
          orderDetails = order.prepaid_request ? this.getOrderDetails(order.prepaid_request) : null;
          break;
        // if order type is pm_request, use request description
        case "pm_request":
          orderDetails = order.pm_request ? order.pm_request.request_description : null;
          break;
      }

      return orderDetails;
    }

    // helper function to get order details from prepaid customer order
    private getOrderDetails(orderInfo) {
      let orderDetails = '';
      if (orderInfo.cart.order) {
        const items = orderInfo.cart.order;
        const numOfItems = items.length;
        let index = 0;
        items.forEach(item => {
          index++;
          if (index === numOfItems) {
            orderDetails = orderDetails + item.quantity + ' ' + item.item.item_name;
          } else {
            orderDetails = orderDetails + item.quantity + ' ' + item.item.item_name + ', ';
          }
        });
      } else {
        orderDetails = orderInfo.order_description;
      }

      return orderDetails;
    }

    // helper function to extract the city, state of an order
    getLocation(order) {
      let returnVal: string;
  
      if(order.pm_request) {
        returnVal = order.pm_request.address.city + ', ' + order.pm_request.address.state;
      } else if (order.prepaid_request) {
        returnVal = order.prepaid_request.address.city + ', ' + order.prepaid_request.address.state;
      } else {
        returnVal = '';
      }
  
      return returnVal;
    }

    // helper function to extract the itemCount in an order
    getItemCount(order) {
      let returnVal;

      if(order.custom_prepaid_order) {
        returnVal = 'Custom Order';
      } else {
        returnVal = order.cart.order.length;
      }

      return returnVal;
    }

    // helper function to get order notes
    getOrderNotes(order) {
      let orderNotes;
      
      switch(order.order_type){
        case "prepaid_customer_order":
          if (order.prepaid_customer_order) {
            orderNotes = order.prepaid_request.notes ? order.prepaid_request.notes : null;
          } else {
            orderNotes = null;
          }
          break;
        case "pm_request":
          if (order.pm_request) {
            orderNotes = order.pm_request.notes ? order.pm_request.notes : null;
          } else {
            orderNotes = null;
          }
          break;
      }

      return orderNotes;
    }

    // helper function to get image count
    getImageCount(order) {
      let imageCount;
      switch(order.order_type){
        // if order type is prepaid_customer_order, there won't be any images
        case "prepaid_customer_order":
          imageCount = 0;
          break;
        // if order type is pm_request, get image count
        case "pm_request":
          if (order.pm_request) {
            imageCount = order.pm_request.request_images ? order.pm_request.request_images : 0;
          } else {
            imageCount = 0;
          }
          break;
      }

      return imageCount;
    }

    // helper function to get readable text of status
    formatStatus(orderStatus) {
      let orderStatusText;
      switch(orderStatus){
        case "unclaimed":
          orderStatusText = 'Open';
          break;
        case "claimed":
          orderStatusText = 'Scheduled';
          break;
        case "driver-complete":
          orderStatusText = 'Processing';
          break;
        case "incomplete":
          orderStatusText = 'Awaiting Payment';
          break; 
        case "successful":
          orderStatusText = 'Complete';
          break; 
        case "unsuccessful":
          orderStatusText = 'Cancelled';
          break;          
      }

      return orderStatusText;
    }
}