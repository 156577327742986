import { Component, OnInit } from '@angular/core';
import { OrderService } from 'app/services/order/order.service';
import { AuthService } from 'app/services/auth/auth.service';

@Component({
  selector: 'app-open-orders',
  templateUrl: './open-orders.component.html',
  styleUrls: ['./open-orders.component.scss']
})
export class OpenOrdersComponent implements OnInit {
  userRole: string;
  isSuperAdmin = false;
  isDriver = false;
  text = '';
  orderCount: number;

  constructor(private orderService: OrderService, private authService: AuthService ) {
    // this if statement is needed due to a unintended behavior in Auth0
    // when a user logs in isAuthenticated() returns false until all auth0 code executes
    // authService.readyStatic checks to see if auth0 has finsihed executing, if false, subscribe and wait till complete
    if (this.authService.readyStatic) {
      if (this.authService.isAuthenticated()) {
        this.setRole();
        this.setText();
        if (this.isSuperAdmin) {
          this.getPrepaidCustomerOrderCountAdmin();
        } else if (this.isDriver) {
          this.getCountDriver()
        }
      }
    } else {
      this.authService.ready.subscribe(ready => {
        this.setRole();
        this.setText();
        if (this.userRole === 'super-admin') {
          this.getPrepaidCustomerOrderCountAdmin();
        } else if (this.userRole === 'driver') {
          this.getCountDriver()
        }
      })
    }
  }

  ngOnInit() {
  }

  setRole() {
    this.userRole = this.authService.getRole();
    if (this.userRole === 'super-admin') {
      this.isSuperAdmin = true;
      this.isDriver = false;
    } else if (this.userRole === 'driver') {
      this.isSuperAdmin = false;
      this.isDriver = true;
    }
  }

  getPrepaidCustomerOrderCountAdmin() {
    this.orderService.retrieveNumOfOrders('prepaid_customer_order').subscribe(response => {
      if(response.success) {
        this.orderCount = response.data.Count;
      } else {
        this.orderCount = -1;
      }
    }, err => {
      this.orderCount = -1;
      console.log(err);
    });
  }

  getCountDriver() {
    this.orderService.retrieveNumOfOrders(null, 'unclaimed').subscribe(response => {
      if(response.success) {
        this.orderCount = response.data.Count;
      } else {
        this.orderCount = -1;
      }
    }, err => {
      this.orderCount = -1;
      console.log(err);
    });
  }

  setText() {
    if (this.userRole === 'super-admin') {
      this.text = 'All Orders';
    } else if (this.userRole === 'driver') {
      this.text = 'Open Orders';
    } else {
      this.text = 'ERROR'
    }
  }

}
