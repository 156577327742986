import {Component } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';

@Component({
    selector: 'landing-page',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.css']
})
export class LandingComponent {

    constructor( public auth: AuthService ) { }

}
