// Core Imports
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { HttpService } from './services/http/http.service';
import { HttpClientModule } from '@angular/common/http';

// Modules
import { DashboardModule } from './dashboard/dashboard.module';

// Components
import { UserProfileComponent } from './user-profile/user-profile.component';
import { LandingComponent } from './landing/landing.component';
import { ViewOrdersComponent } from './pages/view-orders/view-orders.component';
import { ViewOrderDetailComponent } from './pages/view-order-detail/view-order-detail.component';
import { CustomTransactionComponent } from './pages/custom-transaction/custom-transaction.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { MyOrdersComponent } from './pages/my-orders/my-orders.component';
import { SubmitRequestComponent } from './pages/submit-request/submit-request.component';
import { CompleteOrderComponent } from './pages/complete-order/complete-order.component';
import { CustomOrderComponent } from './pages/custom-order/custom-order.component';

// Services
import { AuthService } from './services/auth/auth.service';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { ProfileService } from './services/profile/profile.service';
import { OrderService } from './services/order/order.service';
import { StringUtilsService } from './services/string-utils/string-utils.service';
import { NotificationService } from './services/notification/notification.service';

// Mat Imports
import {
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatCardModule,
  MatSelectModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatStepperModule,
  MatExpansionModule,
  MatDialogModule,
  MatGridListModule
} from '@angular/material';

// Prime NG Imports
import { TableModule } from 'primeng/table';
import { GMapModule } from 'primeng/gmap';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputMaskModule } from 'primeng/inputmask';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { InputTextModule } from 'primeng/primeng';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';

// PWA/Mobile Configs
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CustomPMRequestComponent } from './pages/custom-pmrequest/custom-pmrequest.component';
import { ViewRequestsComponent } from './pages/view-requests/view-requests.component';
import { ViewCompletedOrdersComponent } from './pages/view-completed-orders/view-completed-orders.component';



@NgModule({
  declarations: [
    AppComponent,
    UserProfileComponent,
    LandingComponent,
    ViewOrdersComponent,
    ViewOrderDetailComponent,
    CustomTransactionComponent,
    UnauthorizedComponent,
    MyOrdersComponent,
    SubmitRequestComponent,
    CompleteOrderComponent,
    CustomOrderComponent,
    CustomPMRequestComponent,
    ViewRequestsComponent,
    ViewCompletedOrdersComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    DashboardModule,
    // Material Imports
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatStepperModule,
    MatExpansionModule,
    MatDialogModule,
    MatGridListModule,
    // PrimeNG Imports
    TableModule,
    GMapModule,
    ButtonModule,
    DialogModule,
    ProgressSpinnerModule,
    InputMaskModule,
    CheckboxModule,
    DropdownModule,
    MultiSelectModule,
    ConfirmDialogModule,
    InputTextModule,
    FileUploadModule,
    InputTextareaModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    AuthService,
    AuthGuardService,
    HttpService,
    ProfileService,
    OrderService,
    StringUtilsService,
    NotificationService,
    // PrimeNG Imports
    ConfirmationService,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
