import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { ROUTES_ADMIN, ROUTES_DRIVER, ROUTES_PM, ROUTES_UNAUTHORIZED } from '../../models/routes';

declare const $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
  routes: any;
  menuItems: any[] = [];
  role;

  constructor( public authService: AuthService, private router: Router) {
    // this if statement is needed due to a unintended behavior in Auth0
    // when a user logs in isAuthenticated() returns false until all auth0 code executes
    // authService.readyStatic checks to see if auth0 has finsihed executing, if false, subscribe and wait till complete
    if (this.authService.readyStatic) {
      if (this.authService.isAuthenticated()) {
        this.setControls();
        this.getMenuItems();
      }
    } else {
      this.authService.ready.subscribe(ready => {
        this.setControls();
        this.getMenuItems();
      })
    }
  }

  // sets the routes based on the role of user
  setControls() {
    this.role = this.authService.getRole();
    if (this.role === 'super-admin') {
      this.routes = ROUTES_ADMIN;
    }
    else if(this.role === 'driver') {
      this.routes = ROUTES_DRIVER;
    }
    else if(this.role === 'property-manager') {
      this.routes = ROUTES_PM;
    }
    else {
      this.routes = ROUTES_UNAUTHORIZED;
    }
  }

  getMenuItems() {
    if (this.menuItems.length === 0) {
      for (const route of this.routes) {
        if (route.menuItem) {
          this.menuItems.push(route);
        }
      }
    }
  }

  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
