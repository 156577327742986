import { Component, OnInit } from '@angular/core';
import { OrderService } from 'app/services/order/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'app/services/auth/auth.service';
import { POSSIBLE_STATUS_PM_REQUEST, POSSIBLE_STATUS_CUSTOMER_ORDER } from '../../models/possible-status';
import { StringUtilsService } from 'app/services/string-utils/string-utils.service';
import { ProfileService } from 'app/services/profile/profile.service';

@Component({
  selector: 'app-view-order-detail',
  templateUrl: './view-order-detail.component.html',
  styleUrls: ['./view-order-detail.component.scss']
})
export class ViewOrderDetailComponent implements OnInit {
  // control variables
  isDriver = false;
  isSuperAdmin = false;
  isPropertyManager = false;
  canEdit = false;
  userRole: string;
  
  // data class variables
  type: string; // this is needed right now due to old way of getting order type
  orderType: String; // this is the correct way to check order type

  orderId: any;
  order: any;
  orderOriginal: any;
  statusForm: FormGroup;
  eta = null;
  requestImages: any;
  pmImages: any;
  hpImages: any;
  otherImages: any;
  imagesIncluded = false;

  MULTIPLIER = .8;
  PM_REQUEST = 'pm_request';
  PREPAID_REQUEST = 'prepaid_request';

  // control class variables
  editMode = false;
  editButtonText = 'Edit';
  
  // hauler notification variables
  sendNotificationText = 'Send Notification';
  showHaulersDropdown = false;
  notifyAllHaulers = true;
  listOfHaulers: any;
  haulerToNotify: any;

  // other control variables
  isScheduled = false;
  showError = false;
  showOrderUnavailableError = false;
  showSpecialHaulProAssignedError = false;
  showFetchError = false;
  showConfirmationDialog = false;
  orderConfirmed = false;
  showCompleteOrderButton = false;
  showAcceptOrderButton = false;
  showAdminInfo = false;
  showSensitiveInfo = false;
  showChargeInfoCheckbox = false;
  showChargeInfoField = false;
  showDriverEarningsField = false;

  possibleStatus;
  dropDown;

  possiblePickupTimes = [
    { value: '8:00AM - 10:00AM',      text: '8:00AM - 10:00AM'  },
    { value: '10:00AM - 12:00PM',     text: '10:00AM - 12:00PM' },
    { value: '12:00PM - 2:00PM',      text: '12:00PM - 2:00PM'  },
    { value: '2:00PM - 4:00PM',       text: '2:00PM - 4:00PM'   },
    { value: '4:00PM - 6:00PM',       text: '4:00PM - 6:00PM'   },
    { value: '6:00PM - 8:00PM',       text: '6:00PM - 8:00PM'   },
  ];

  constructor(private route: ActivatedRoute, private orderService: OrderService, private fb: FormBuilder, private authService: AuthService, private router: Router,
      private profileService: ProfileService,  private stringUtils: StringUtilsService) {
        this.orderId = this.route.snapshot.paramMap.get('orderId');
        
        // this if statement is needed due to a unintended behavior in Auth0
        // when a user logs in isAuthenticated() returns false until all auth0 code executes
        // authService.readyStatic checks to see if auth0 has finsihed executing, if false, subscribe and wait till complete
        if (this.authService.readyStatic) {
          if (this.authService.isAuthenticated()) {
            this.userRole = this.authService.getRole();
            this.setControlVariables();
            this.checkPermission();
          }
        } else {
          this.authService.ready.subscribe(ready => {
            this.userRole = this.authService.getRole();
            this.setControlVariables();
            this.checkPermission();
          });
        }
  }

  ngOnInit() { }

  setControlVariables() {
    if (this.userRole === 'super-admin') {
      this.isSuperAdmin = true;
      this.isDriver = false;
      this.isPropertyManager = false;
    } else if (this.userRole === 'driver') {
      this.isSuperAdmin = false;
      this.isDriver = true;
      this.isPropertyManager = false;
    } else if (this.userRole === 'property-manager') {
      this.isSuperAdmin = false;
      this.isDriver = false;
      this.isPropertyManager = true;
    } else {
      this.router.navigate( ['/unauthorized'] );
    }
  }

  // function to check if the authenticated user has neccessary permissions to view/edit page content
  checkPermission() {
    // Check if they have super admin privileges
    if (this.isDriver ||  this.isPropertyManager || this.isSuperAdmin) {
      this.fetchOrderInformation();
    } else {
      // unauthorized to view order-details
      this.router.navigateByUrl('/unauthorized');
    }
    // Check if they have edit privileges
    if (this.isSuperAdmin) {
      this.canEdit = true;
      this.initializeForm();
    }
  }

  // control function to get order information
  fetchOrderInformation() {
    // TODO: Update to use new/updated endpoint to fetch data from DynamoDB
    this.orderService.getOrderDetail(this.orderId).subscribe(result => {
      if(result.success) {
        this.showFetchError = false;
        this.orderOriginal = result.data;
        this.orderService.setCachedOrder(result.data);
        this.order = this.formatOrderInformation(result.data);
        this.setControls();
      } else {
        this.showFetchError = true;
      }
    }, error => {
      this.showFetchError = true;
    });

  }

  setControls() {
    this.checkShowSensitiveInfo();
    // set possible status'
    if (this.orderService.cachedOrder.prepaid_request) {
      this.orderType = 'prepaid_request';
      this.possibleStatus = POSSIBLE_STATUS_CUSTOMER_ORDER;
      this.dropDown = this.possibleStatus;

    } else if (this.orderService.cachedOrder.pm_request) {
      this.orderType = 'pm_request';
      this.possibleStatus = POSSIBLE_STATUS_PM_REQUEST;
      this.dropDown = this.possibleStatus;

    }

    // check orders status to determine view
    if (this.order.status === 'Complete') {
      this.checkCompletedOrder();
    } else if (this.order.status === 'Scheduled') {
      this.isScheduled = true;
      this.checkScheduledOrder();
    } else if (this.order.status === 'Open') {
      this.showAcceptOrderButton = true;
      this.showCompleteOrderButton = false;
    }
  }

  checkShowSensitiveInfo() {
    switch(this.userRole) {
      case 'super-admin': {
        // super admins can see sensitive information
        this.showSensitiveInfo = true;
        this.getListOfAllHaulers();
        break;
      }
      case 'property-manager': {
        // property managers can only see full details of requests that they submit or 
        const user_id = this.authService.getUserId();
        if(user_id === this.orderOriginal.submitted_by_user_id || user_id === this.orderOriginal.property_manager_user_id){
          this.showSensitiveInfo = true;
        } else {
          this.showSensitiveInfo = false;
        }
        break;
      }
      case 'driver': {
        // drivers can see full details of orders/requests that they have accepted or completed
        if (this.isDriverAssigned() && this.order.haulproAssignedUserId === this.authService.getUserId()) {
          this.showSensitiveInfo = true;
        } else {
          this.showSensitiveInfo = false;
        }
        break;
      }
      default: {
        this.showSensitiveInfo = false;
      }
    }
  }

  isDriverAssigned() {
    return this.order.scheduledInfo ? true : false;
  }

  // function to check if the order is already claimed and if authenticated user is viewing the order info
  checkScheduledOrder() {
    if (this.order.scheduledInfo) {
      if (this.showSensitiveInfo) {
        this.showCompleteOrderButton = true;
        this.showAcceptOrderButton = false;
      }
    }
  }

  checkCompletedOrder() {
    if (this.order.scheduledInfo) {
      if (this.showSensitiveInfo) {
      }
    }
    this.showAcceptOrderButton = false;
    this.showCompleteOrderButton = false;
  }

  // function to create the form for super admins to be able to update status
  initializeForm() {
    let currentStatus: string;
    if (this.order) {
      if (this.order.order_status === 'succeeded') {
        currentStatus = 'unclaimed';
      } else {
        currentStatus = this.order.order_status;
      }
    }
    this.statusForm = this.fb.group({
      status: [ currentStatus, Validators.required],
      includeChargeInfo: [false],
      chargeInfo: this.fb.group({
        total_price: ['', Validators.required],
        custom_driver_earning: [false],
        driver_earning: ['']
      })
    });
  }

  getListOfAllHaulers() {
    this.profileService.getListOfHaulers().subscribe(result => {
      if(result.success) {
        this.listOfHaulers = result.data;
      }
    }, err => {
      alert('Error fetching Haulers');
      this.listOfHaulers = [];
      console.log(err)
    });
  }

  showTransactionDetailsCheckbox(status) {
    if (this.orderType === this.PM_REQUEST) {
      if (status === 'incomplete') {
        this.showChargeInfoCheckbox = true;
      } else {
        this.showChargeInfoCheckbox = false;
        // reset chargeInfo form group
        this.statusForm.controls['includeChargeInfo'].setValue(false);
        // this.statusForm.get('chargeInfo').controls['custom_driver_earning'].setValue(false);
        this.showChargeInfo(false);
      }
    }

  }

  showChargeInfo(show) {
    // console.log(add);
    if (show) {
      this.showChargeInfoField = true;
    } else {
      this.showChargeInfoField = false;
    }
  }

  showCustomDriverEarning (show) {
    if (show) {
      this.showDriverEarningsField = true;
    } else {
      this.showDriverEarningsField = false;
    }
  }

  // function to clean up order info into displayable structure
  formatOrderInformation(order) {
    const returnVal = {
      id: order.order_id,
      date_created: order.date_created,
      name: order.customer_info ? order.customer_info.fName + ' ' + order.customer_info.lName : null,
      confirmationNumber: isNaN(order.confirmation_number) ? order.confirmation_number : order.confirmation_number.toString(),
      location: this.stringUtils.getLocation(order),
      address: this.stringUtils.formatAddressString(order),
      email: order.customer_info ? order.customer_info.email : null,
      phone: order.customer_info ? order.customer_info.phone : null,
      itemCount: order.prepaid_request ? this.stringUtils.getItemCount(order.prepaid_request) : 'Custom Order',
      price: order.order_total ? order.order_total : -1,
      haulerEarning: order.haulpro_earnings ? order.haulpro_earnings : -1,
      haulproAssignedUserId: order.haulpro_assigned_user_id ? order.haulpro_assigned_user_id : null,
      orderType: this.stringUtils.formatOrderType(order),
      orderDetails: this.stringUtils.formatOrderDetails(order),
      pickupInfo: order.prepaid_request ? order.prepaid_request.pickup_info : null,
      pickupTime: this.stringUtils.formatPickupTime(order),
      scheduledInfo: order.schedule_info ? order.schedule_info : null,
      status: this.stringUtils.formatStatus(order.order_status),
      // status_message: order.order_status_message,
      notes: order.haulpro_notes ? order.haulpro_notes : null,
      imageCount: this.stringUtils.getImageCount(order)
    }

    this.getRequestImages();
    return returnVal;
  }

  // helper function to get images
  getRequestImages() {
    this.orderService.pmImages.subscribe(images => {
      this.pmImages = images;
      if (this.pmImages.length > 0) {
        this.imagesIncluded = true;
      }
    }, error => {
      console.log(error);
    });
    this.orderService.hpImages.subscribe(images => {
      this.hpImages = images;
      if (this.hpImages.length > 0) {
        this.imagesIncluded = true;
      }
    }, err => {
      console.log(err);
    });
    this.orderService.otherImages.subscribe(images => {
      this.otherImages = images;
      this.imagesIncluded = true;
    }, err => {
      console.log(err);
    })
  }

  // function to enable super admin to manually change status
  edit() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.editButtonText = 'Save';
    } else {
      const newStatus = this.getNewStatus();
      this.editButtonText = 'Edit';
      if (newStatus) {
        this.updateOrderStatus(newStatus, false, null);

      }
    }
  }

  // function to enable super admin to send SMS notification alerting haulers
  updateNotificationControl() {
    this.showHaulersDropdown = !this.showHaulersDropdown;
    if (this.showHaulersDropdown){
      this.sendNotificationText = 'Notify';
    } else {
      this.sendNotification();
      this.sendNotificationText = 'Send Notification';
    }
  }

  private sendNotification() {
    this.orderService.notifyHaulers(this.haulerToNotify).subscribe(response => {
      if(response.success) {
        alert('Successfully Alerted Hauler(s)');
      }
    }, err => {
      console.error(err);
      alert('Error sending SMS: \n' + JSON.parse(err));
    });
  }

  cancel() {
    this.editMode = !this.editMode;
    this.editButtonText = 'Edit';
  }

  // function to update order status
  updateOrderStatus(newStatus, haulproAssigned, eta) {
    const status = {
      newStatus: newStatus,
      oldStatus: {
        value: this.order.status,
        text: this.order.status_message
      }
    }

    switch(newStatus.value) {
      // this flow is when the new status is unclaimed (open)
      case 'unclaimed':
        this.orderService.updateOrderStatusToOpen(this.order.id, this.order.date_created, this.authService.getUserId()).subscribe(result => {
          if (result.success) {
            this.refreshView();
          } else {
            this.showError = true;
          }
        }, err => {
          console.error(err);
          this.showError = true;
        });
        break;
      // this flow is when the new status is claimed (scheduled)
      case 'claimed':
        let haulerEarning: number;
        if (this.orderType === this.PM_REQUEST) {
          haulerEarning = null;
        } else if (this.order === this.PREPAID_REQUEST) {
          if (this.order.haulerEarning) {
            haulerEarning = this.order.haulerEarning;
          } else {
            haulerEarning = this.order.price * this.MULTIPLIER;
          }
        }
        this.orderService.updateOrderStatusToScheduled(this.order.id, this.order.date_created, status,
          this.authService.getUserId(), haulproAssigned, eta, this.order.email, haulerEarning).subscribe(result => {
            if (result.success) {
              this.refreshView();
            } else {
              this.showError = true;
            }
          }, err => {
            console.error(err);
            if (err.status === 401) {
              this.showOrderUnavailableError = true;
              this.showError = false;
            } else {
              this.showError = true;
              this.showOrderUnavailableError = false;
            }
          });
        break;
      // this flow is when the super-admin marks the status as driver-complete
      case 'driver-complete':
        const notes = {
          loadSize: 'N/A',
          orderNotes: 'This order marked Driver-Complete by Super Admin'
        }
        this.orderService.updateOrderStatusToDriverComplete(this.order.id, this.order.date_created, status, this.authService.getUserId(),
          true, this.order.email, notes).subscribe(result => {
            if (result.success) {
              this.refreshView()
            } else {
              console.log(result);
              this.showError = true;
            }
          }, err => {
            console.log(err);
            this.showError = true;
          });
        break;
      // this flow is when the super admin has sent the invoice and is awaiting payment
      case 'incomplete':
        const chargeInfo = this.getChargeInfo();
        this.orderService.updateRequestStatusToIncomplete(this.order.id, this.order.date_created, chargeInfo).subscribe(result => {
          if (result.success) {
            this.refreshView();
          } else {
            this.showError = true;
          }
        }, err => {
          console.error(err);
          this.showError = true;
        });
        break;
      // this flow is when the payment has been received and the order is complete
      case 'successful':
        if(this.order.haulproAssignedUserId) {
          this.orderService.updateOrderStatusToComplete(this.order.id, this.order.date_created, this.order.haulproAssignedUserId,
            true, null, this.order.email, null).subscribe(result => {
              if (result.success) {
                this.refreshView();
              } else {
                this.showError = true;
              }
            }, err => {
              console.error(err);
              this.showError = true;
            });
        } else {
          this.showSpecialHaulProAssignedError = true;
        }
        break;
      // this flow is when the order is cancelled or no longer needed
      case 'unsuccessful':
        this.orderService.updateOrderStatusToCancelled(this.order.id, this.order.date_created, this.authService.getUserId()).subscribe( result => {
          if (result.success) {
            this.refreshView();
          } else {
            this.showError = true;
          }
        }, err => {
          console.error(err);
          this.showError = true;
        });
        break;  
      default:
        console.log("in default");
        break;

    }

  }

  refreshView() {
    this.showError = false;
    this.showSpecialHaulProAssignedError = false;
    this.fetchOrderInformation();
  }

  getChargeInfo() {
    const returnVal = {
      includeChargeInfo: false,
      custom_driver_earning: false,
      total_price: null,
      hauler_earnings: null
    };
    if (this.statusForm) {
      if (this.statusForm.controls['includeChargeInfo'].value) {
        // charge info included in status update
        returnVal.includeChargeInfo = true;
        const chargeInfo = this.statusForm['controls'].chargeInfo.value;
        returnVal.total_price = chargeInfo.total_price;
        if (chargeInfo.custom_driver_earning) {
          returnVal.custom_driver_earning = true;
          returnVal.hauler_earnings = chargeInfo.driver_earning;
        } else {
          returnVal.custom_driver_earning = false;
          returnVal.hauler_earnings = chargeInfo.total_price * this.MULTIPLIER;
        }
      }
    } else {
      // do nothing
    }
    return returnVal;
  }

  // function to determine new status from dropdown when super admin manually changes status
  getNewStatus() {
    let newStatus: any;
    const newStatusVal = this.statusForm.get('status').value;
    newStatus = this.possibleStatus.find(i => i.value === newStatusVal);
    if (this.order.order_status === newStatusVal) {
      newStatus = null;
    }
    return newStatus;
  }

  // helper function to trigger confirmation modal
  acceptOrder() {
    this.showConfirmationDialog = true;
  }

  // function to allow haulpro to accept order
  confirmOrder() {
    this.showConfirmationDialog = false;
    if (this.orderConfirmed) {
      this.updateOrderStatus(this.possibleStatus[1], true, this.eta.value);
    }
  }

  // function to allow haulpro to complete order
  completeOrder() {
    if (this.orderType === this.PREPAID_REQUEST) {
      this.router.navigateByUrl('/orders/complete/order/' + this.order.confirmationNumber);
    } else if (this.orderType === this.PM_REQUEST) {
      this.router.navigateByUrl('/orders/complete/request/' + this.order.confirmationNumber);
    }
  }

  // function to take user back to page the came from
  goBack() {

    switch(this.orderType) {
      case this.PM_REQUEST: {
        // if super-admin, go back to all-pm-requests page 
        if (this.isSuperAdmin) {
          this.router.navigate( ['/pm-requests'] );
        } else if(this.isPropertyManager) {
          // property managers can only go back to my orders
          this.router.navigate( ['/my-orders'] );
        } else if (this.isDriver && !this.showSensitiveInfo) { //driver is on available orders page
          this.router.navigate( ['/orders'] );
        } else if (this.isDriver && this.showSensitiveInfo) {//driver is on my-orders page
          this.router.navigate( ['/my-orders'] ); 
        }
       break; 
      }
      case this.PREPAID_REQUEST: {
        if (this.isSuperAdmin) {
          this.router.navigate( ['/orders'] );
        } else if (this.isDriver && !this.showSensitiveInfo) { //driver is on available orders page
          this.router.navigate( ['/orders'] );
        } else if (this.isDriver && this.showSensitiveInfo) {//driver is on my-orders page
          this.router.navigate( ['/my-orders'] ); 
        }
        break;
      }
      default: {
        this.router.navigate( ['/'] );
        break;
      }
    }
  }
}

