import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// Services
import { AuthService } from 'app/services/auth/auth.service';
import { StringUtilsService } from 'app/services/string-utils/string-utils.service';
import { OrderService } from 'app/services/order/order.service';

// Other imports
import { POSSIBLE_STATUS_CUSTOMER_ORDER, POSSIBLE_STATUS_PM_REQUEST } from '../../models/possible-status';
import { POSSIBLE_LOAD_SIZES } from '../../models/possible-load-sizes';

@Component({
  selector: 'app-complete-order',
  templateUrl: './complete-order.component.html',
  styleUrls: ['./complete-order.component.scss']
})
export class CompleteOrderComponent implements OnInit {
  permissionNeededOrder = 'orders-view';
  permissionNeededRequest = 'request-view';
  type: string;
  orderType: string;
  orderId: string;

  order: any;

  // control variables
  showNotDriverMessage = false;
  showDetails = false;
  loadSizeFormFieldInitalized = false;
  imagesUploaded = false;
  enableSubmit = false;
  tooManyFilesError = false;
  showErrorMessage = false;
  showNoImagesError = false;
  processing = false;

  MULTIPLIER = .8;
  PM_REQUEST = 'pm_request';
  PREPAID_REQUEST = 'prepaid_request';
  possibleStatus;
  possibleLoadSizes = POSSIBLE_LOAD_SIZES;
  completeOrderForm: FormGroup;
  completeOrderImages: Set<File> = new Set();
  completeOrderImagesList: File[] = [];


  constructor( private route: ActivatedRoute, private authService: AuthService,
      private router: Router, private stringUtils: StringUtilsService, private fb: FormBuilder,
      private orderService: OrderService) {
        this.type = this.route.snapshot.paramMap.get('key');
        this.orderId = this.route.snapshot.paramMap.get('orderId');
        // this if statement is needed due to a unintended behavior in Auth0
        // when a user logs in isAuthenticated() returns false until all auth0 code executes
        // authService.readyStatic checks to see if auth0 has finsihed executing, if false, subscribe and wait till complete
        if (this.authService.readyStatic) {
          if (this.authService.isAuthenticated()) {
            this.checkPermission();
          }
        } else {
          this.authService.ready.subscribe(ready => {
            this.checkPermission();
          });
        }
  }

  ngOnInit() { }

  // control function to check if the authenticated user has neccessary permissions to view/edit page content
  checkPermission() {
    if (this.authService.isDriver) {
      this.getOrderData();
    } else {
      // unauthorized to view order-details
      this.router.navigateByUrl('/unauthorized');
    }
  }

  getOrderData() {
    // get cached request
    if (this.orderService.cachedOrder) {
      this.order = this.orderService.cachedOrder;
      this.setControls();
    } else {
      this.orderService.getOrderDetail(this.orderId).subscribe(response => {
        if(response.success) {
          this.orderService.setCachedOrder(response.data);
          this.order = response.data;
          this.setControls();
        }
      }, err => {
        // TODO: Handle ERROR HERE
        console.log(err);
      });
    }
  }

  setControls() {
    if (this.order.pm_request) {
      this.orderType = 'pm_request';
      this.possibleStatus = POSSIBLE_STATUS_PM_REQUEST;
      this.loadSizeFormFieldInitalized = true;
    } else if (this.order.prepaid_request) {
      this.orderType = 'prepaid_request';
      this.possibleStatus = POSSIBLE_STATUS_CUSTOMER_ORDER;
      this.loadSizeFormFieldInitalized = false;
    }
    this.order = this.formatOrderInformation(this.order);
    if (this.order.haulproAssignedUserId === this.authService.getUserId()) {
      this.showDetails = true;
      this.initializeForm();
    } else {
      this.showNotDriverMessage = true;
    }
  }

  initializeForm() {
    // pm_request need loadSize information whereas prepaid_requests don't
    if (this.orderType === this.PM_REQUEST) {
      this.completeOrderForm = this.fb.group({
        policyProcedures: [false, Validators.required],
        completeOrderImages: this.completeOrderImages,
        loadSize: ['', Validators.required],
        orderNotes: ['']
      });
    } else if (this.orderType === this.PREPAID_REQUEST) {
        this.completeOrderForm = this.fb.group({
          policyProcedures: [false, Validators.required],
          completeOrderImages: this.completeOrderImages,
          orderNotes: ['']
        });
    }
  }

  // control function to clean up order info into displayable structure
  formatOrderInformation(order) {
    const returnVal = {
      id: order.order_id,
      date_created: order.date_created,
      name: order.customer_info ? order.customer_info.fName + ' ' + order.customer_info.lName : null,
      confirmationNumber: isNaN(order.confirmation_number) ? order.confirmation_number : order.confirmation_number.toString(),
      location: this.stringUtils.getLocation(order),
      address: this.stringUtils.formatAddressString(order),
      email: order.customer_info ? order.customer_info.email : null,
      phone: order.customer_info ? order.customer_info.phone : null,
      itemCount: order.prepaid_request ? this.stringUtils.getItemCount(order.prepaid_request) : 'Custom Order',
      price: order.order_total ? order.order_total : -1,
      haulerEarning: order.haulpro_earnings ? order.haulpro_earnings : -1,
      haulproAssignedUserId: order.haulpro_assigned_user_id ? order.haulpro_assigned_user_id : null,
      orderType: this.stringUtils.formatOrderType(order),
      orderDetails: this.stringUtils.formatOrderDetails(order),
      pickupInfo: order.prepaid_request ? order.prepaid_request.pickup_info : null,
      pickupTime: this.stringUtils.formatPickupTime(order),
      scheduledInfo: order.schedule_info ? order.schedule_info : null,
      status: this.stringUtils.formatStatus(order.order_status),
      // status_message: order.order_status_message,
      notes: this.stringUtils.getOrderNotes(order),
      imageCount: this.stringUtils.getImageCount(order)
    }

    return returnVal;
  }


  completeOrder() {
    this.processing = true;
    const status = {
      newStatus: this.possibleStatus[2],
      oldStatus: this.possibleStatus[1]
    }
    const notes = {
      loadSize: this.loadSizeFormFieldInitalized ? this.completeOrderForm.get('loadSize').value : 'N/A',
      orderNotes: this.completeOrderForm.get('orderNotes').value
    }


    if (this.orderType === this.PM_REQUEST) {
      this.orderService.updateOrderStatusToDriverComplete(this.order.id, this.order.date_created, status, this.authService.getUserId(),
          true, this.order.email, notes).subscribe(response => {
            if (response.success) {
              console.log(response);
              this.showErrorMessage = false;
              this.orderService.uploadImages(this.order.id, this.completeOrderImages, 'hp');
              this.checkImageUploadComplete();
            } else {
              console.log(response);
              this.showErrorMessage = true;
            }
          }, err => {
            console.log(err);
            this.showErrorMessage = true;
          });
    } else if (this.orderType === this.PREPAID_REQUEST) {
      this.orderService.updateOrderStatusToComplete(this.order.id, this.order.date_created, this.authService.getUserId(),
          true, null, this.order.email, notes).subscribe(response => {
            if(response.success) {
              console.log(response);
              this.showErrorMessage = false;
              this.orderService.uploadImages(this.order.id, this.completeOrderImages, 'hp');
              this.checkImageUploadComplete();
            } else {
              console.log(response);
              this.showErrorMessage = true;
            }
        }, err => {
          console.log(err);
          this.showErrorMessage = true;
        });
    }
  }

  checkImageUploadComplete() {
    this.orderService.imageUploadComplete.subscribe(complete => {
      if(complete) {
        this.processing = false;
        if(this.orderType === this.PM_REQUEST) {
          this.router.navigateByUrl('/pm-requests/view/' + this.order.confirmationNumber);
        } else if(this.orderType === this.PREPAID_REQUEST) {
          this.router.navigateByUrl('/orders/view/' + this.order.confirmationNumber);
        }
      } else {
        // handle error
      }
    }, err => {
      alert('An error has occured. Please refresh page.');
      console.log(err);
    })
  }

  uploadImages(images, form) {
    const files: { [key: string]: File } = images;
    if (images.length > 0) {
      this.showNoImagesError = false;
      if (images.length > 2) {
        this.tooManyFilesError = true;
      } else {
        for (const key in files) {
          if ( !isNaN(parseInt(key, 10)) ) {
            this.completeOrderImages.add(files[key]);
            this.completeOrderImagesList.push(files[key]);
          }
        }
        this.tooManyFilesError = false;
        this.imagesUploaded = true;
        form.clear();
      }
    }
  }

  checkSubmission() {
    if (this.completeOrderForm.valid) {
      this.showErrorMessage = false;
      if (!this.imagesUploaded) {
        this.showNoImagesError = true;
        this.tooManyFilesError = false;
      } else {
        this.completeOrder();
      }
    } else {
      this.showErrorMessage = true;
    }
  }

  navigateBackToOrderDetails() {
    if (this.type === 'request') {
      this.router.navigateByUrl('/pm-requests/view/' + this.order.confirmationNumber);
    } else if (this.type === 'order') {
      this.router.navigateByUrl('/orders/view/' + this.order.confirmationNumber);
    }
  }

}
