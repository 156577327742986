export const POSSIBLE_STATUS_PM_REQUEST = [
    { value: 'unclaimed',           text: 'Open'      },
    { value: 'claimed',             text: 'Scheduled' },
    { value: 'driver-complete',     text: 'Processing'},
    { value: 'incomplete',          text: 'Awaiting Payment'},
    { value: 'successful',          text: 'Complete'  },
    { value: 'unsuccessful',        text: 'Cancelled' },
]

export const POSSIBLE_STATUS_CUSTOMER_ORDER = [
    { value: 'unclaimed',           text: 'Open'      },
    { value: 'claimed',             text: 'Scheduled' },
    { value: 'successful',          text: 'Complete'  },
    { value: 'unsuccessful',        text: 'Cancelled' },
]
