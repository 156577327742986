import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth/auth.service';
import { OrderService } from 'app/services/order/order.service';

@Component({
  selector: 'app-open-requests',
  templateUrl: './open-requests.component.html',
  styleUrls: ['./open-requests.component.scss']
})
export class OpenRequestsComponent {
  userRole = '';
  isSuperAdmin = false;
  isPropertyManager = false;
  text = '';
  requestCount = 0;

  constructor(private authService: AuthService, private orderService: OrderService) {
    // this if statement is needed due to a unintended behavior in Auth0
    // when a user logs in isAuthenticated() returns false until all auth0 code executes
    // authService.readyStatic checks to see if auth0 has finsihed executing, if false, subscribe and wait till complete
    if (this.authService.readyStatic) {
      if (this.authService.isAuthenticated()) {
        this.setRole();
        this.setText();
        if (this.isSuperAdmin) {
          this.getPMRequestCountAdmin();
        } else if (this.isPropertyManager) {
          this.getRequestCountPM()
        }
      }
    } else {
      this.authService.ready.subscribe(ready => {
        this.setRole();
        this.setText();
        if (this.userRole === 'super-admin') {
          this.getPMRequestCountAdmin();
        } else if (this.userRole === 'driver') {
          this.getRequestCountPM()
        }
      })
    }

  }

  setRole() {
    this.userRole = this.authService.getRole();
    if (this.userRole === 'super-admin') {
      this.isSuperAdmin = true;
      this.isPropertyManager = false;
    } else if (this.userRole === 'property-manager') {
      this.isSuperAdmin = false;
      this.isPropertyManager = true;
    }
  }

  setText() {
    if (this.userRole === 'super-admin') {
      this.text = 'All';
    } else if (this.userRole === 'property-manager') {
      this.text = 'My';
    }
  }

  getPMRequestCountAdmin() {
    this.orderService.retrieveNumOfOrders('pm_request').subscribe(response => {
      if(response.success) {
        this.requestCount = response.data.Count;
      } else {
        this.requestCount = -1;
      }
    }, err => {
      this.requestCount = -1;
      console.log(err);
    });
  }

  getRequestCountPM() {
    this.orderService.retrieveNumOfOrders('pm_request').subscribe(response => {
      if(response.success) {
        this.requestCount = response.data.Count;
      } else {
        this.requestCount = -1;
      }
    }, err => {
      this.requestCount = -1;
      console.log(err);
    });
  }

}
