import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { STATES } from '../../models/states.list';
import { environment } from '../../../environments/environment';
import { OrderService } from 'app/services/order/order.service';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth/auth.service';
import { ProfileService } from 'app/services/profile/profile.service';

@Component({
  selector: 'app-custom-order',
  templateUrl: './custom-order.component.html',
  styleUrls: ['./custom-order.component.scss']
})
export class CustomOrderComponent implements OnInit {
  // control variables
  isSuperAdmin = false;
  isDriver = false;
  isPropertyManager = false;
  isAuthorized = false;
  userRole: string;
  notifyAllHaulers = true;
  listOfHaulers: any;
  haulerToNotify: any;
  
  invoiceForm: FormGroup;
  states = STATES;
  stripe: any;
  showErrorMessage = false;
  showServerErrorMessage = false;
  processingPayment = false;

  constructor(private fb: FormBuilder, private orderService: OrderService, private router: Router,
    private authService: AuthService, private profileService: ProfileService) {
    // this if statement is needed due to a unintended behavior in Auth0
    // when a user logs in isAuthenticated() returns false until all auth0 code executes
    // authService.readyStatic checks to see if auth0 has finsihed executing, if false, subscribe and wait till complete
    if (this.authService.readyStatic) {
      if (this.authService.isAuthenticated()) {
        this.userRole = this.authService.getRole();
        this.setControls();
        this.checkPermission();
      }
    } else {
      this.authService.ready.subscribe(ready => {
        this.userRole = this.authService.getRole();
        this.setControls();
        this.checkPermission();
      });
    }
  }

  ngOnInit() {
    this.stripe = StripeCheckout.configure({
      key: environment.stripeKey,
      // image: '../../../assets/img/haulshare_logo.png',
      locale: 'auto',
      token: token => this.handlePayment(token)
    });

    this.profileService.getListOfHaulers().subscribe(result => {
      if(result.success) {
        this.listOfHaulers = result.data;
      }
    }, err => {
      alert('Error fetching Haulers');
      this.listOfHaulers = [];
      console.log(err)
    });
  }

  setControls() {
    if (this.userRole === 'super-admin') {
      this.isSuperAdmin = true;
      this.isDriver = false;
      this.isPropertyManager = false;
      this.isAuthorized = true;
    } else if (this.userRole === 'driver') {
      this.isSuperAdmin = false;
      this.isDriver = true;
      this.isPropertyManager = false;
      this.isAuthorized = false;
    } else if (this.userRole === 'property-manager') {
      this.isSuperAdmin = false;
      this.isDriver = false;
      this.isPropertyManager = true;
      this.isAuthorized = false;
    } else {
      this.router.navigate( ['/unauthorized'] );
    }
  }
  
  checkPermission() {
    if (!this.isAuthorized) {
      this.router.navigate(['/unauthorized']);
    } else {
      this.initializeInvoiceForm();
    }
  }

  initializeInvoiceForm() {
    this.invoiceForm = this.fb.group({
      customerInfo: this.fb.group({
        name: this.fb.group({
          fName: ['', Validators.required],
          lName: ['', Validators.required]
        }),
        address: this.fb.group({
          address_line1: [ '', Validators.required ],
          address_line2: '',
          city: [ '', Validators.required ],
          state: [ '', Validators.required ],
          postal_code: [ '', Validators.required ],
        }),
        phone: [ '', Validators.required],
        email: [''],
      }),
      amount: ['', Validators.required],
      curbside_pickup: [''],
      invoice_description: ['', Validators.required],
      scheduleInfo: this.fb.group({
        datePickup: [ '', Validators.required ],
        timeAny: false,
        timeFrom: ['12:00' ],
        timeTo: [ '12:00' ]
      })
    })
  }

  checkSubmission() {
    if (this.invoiceForm.valid) {
      // console.log('Form Submitted');
      this.showErrorMessage = false;
      this.openPaymentModal();
    } else {
      this.showErrorMessage = true;
    }
  }

  openPaymentModal() {
    const amount = this.invoiceForm.controls.amount.value * 100;

    this.stripe.open({
      name: 'HaulShare',
      description: 'Complete Invoice',
      amount: amount
    });
  }

  handlePayment(token: any) {
    this.processingPaymentModal(true);
    let haulerInfo;
    if (!this.notifyAllHaulers && this.haulerToNotify) {
       haulerInfo = this.haulerToNotify;
    } 

    this.orderService.processPayment(token, this.invoiceForm.value, haulerInfo)
      .subscribe(response => {
        this.processingPaymentModal(false);

        if(response.success) {
          this.showServerErrorMessage = false;
          this.router.navigate(['/orders/view/' + response.data.confirmation_number])
        } else {
          this.showServerErrorMessage = true;
        }
      }, err => {
        this.processingPaymentModal(false);
        this.showServerErrorMessage = true;
      })
    // console.log('Successfully submitting transaction: ' + token);
  }

  processingPaymentModal(open) {
    if (open) {
      this.invoiceForm.disable();
      this.processingPayment = true;
    } else {
      this.processingPayment = false;
      this.invoiceForm.enable();
    }
  }

}
