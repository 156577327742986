import { Injectable } from '@angular/core';
import { POSSIBLE_STATUS_CUSTOMER_ORDER, POSSIBLE_STATUS_PM_REQUEST } from 'app/models/possible-status';

@Injectable({
  providedIn: 'root'
})
export class ListUtilsService {

  private orderKeys = ['']

  constructor() { }

  // helper function to combine orders and requests into one object | No longer needed
  // public combineRequestsAndOrders(orders, requests) {
  //   let combinedList: any[] = [];

  //   if (orders) {
  //     // iterate through orders first
  //     for (let index = 0; index < orders.length; index++) {
  //       let element = this.getValuesFromOrder(orders[index]);
  //       combinedList.push(element);
  //       // reset local element variable
  //       element = null;
  //     }
  //   }

  //   if (requests) {
  //     // iterate through requests second
  //     for (let index = 0; index < requests.length; index++) {
  //       let element = this.getValuesFromRequest(requests[index]);
  //       combinedList.push(element);
  //       // reset local element variable
  //       element = null;
  //     }
  //   }

  //   combinedList = combinedList.slice().sort((a, b) => b.date_created - a.date_created);

  //   return combinedList;
  // }

  // helper function to clean up orders for easy table rendering
  public formatOrders(orders) {
    let orderList: any[] = [];
    for (let index = 0; index < orders.length; index++) {
      let element = this.getValuesFromOrder(orders[index]);
      orderList.push(element);
      // reset local element variable
      element = null;
    }

    orderList = orderList.slice().sort((a, b) => b.date_created - a.date_created);

    return orderList;
  }


  // return only confirmation number, email, date created, date scheduled, and status
  private getValuesFromOrder(order) {
    const returnVal = {
      'confirmation_number': order.confirmation_number ? order.confirmation_number : 'NOT FOUND',
      'email': order.customer_info ? order.customer_info.email : 'NOT FOUND',
      'date_created': order.date_created ? new Date(order.date_created) : 'NOT FOUND',
      'dateScheduled': this.getPickupDateFromOrder(order),
      'status_message': this.getOrderStatusMessage(order.order_status),
      'type': order.order_type ? order.order_type : 'UNKNOWN'
    }
    return returnVal;
  }

  // NO LONGER NEEDED
  // private getValuesFromRequest(request) {
  //   const returnVal = {
  //     'confirmation_number': request.confirmation_number,
  //     'email': request.requestor_info.contactInfo.email,
  //     'date_created': new Date(request.date_created),
  //     'dateScheduled': this.getPickupDateFromOrder(request),
  //     'status_message': request.request_status_message,
  //     'type': 'Property Manager Request'
  //   }
  //   return returnVal;
  // }

  // helper function to get scheduled date for an order
  private getPickupDateFromOrder(order) {
    let returnVal: string;
    if (order.pickup_info) {
      returnVal = order.pickup_info.datePickup;
    } else {
      returnVal = null;
    }
    return returnVal;
  }

  private getOrderStatusMessage(orderStatus) {
    const returnVal = POSSIBLE_STATUS_PM_REQUEST.find(possibleStatus => possibleStatus.value === orderStatus);

    return returnVal.text;
  }

}
