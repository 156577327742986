import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { STATES } from '../../models/states.list';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

// Services
import { AuthService } from 'app/services/auth/auth.service';
import { ProfileService } from 'app/services/profile/profile.service';
import { Subscription, Observable } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { OrderService } from 'app/services/order/order.service';

@Component({
  selector: 'app-custom-pmrequest',
  templateUrl: './custom-pmrequest.component.html',
  styleUrls: ['./custom-pmrequest.component.scss']
})
export class CustomPMRequestComponent implements OnInit {
  // control variables
  isSuperAdmin = false;
  isDriver = false;
  isPropertyManager = false;
  isAuthorized = false;
  userRole: string;

  userProfile: any;
  requestForm: FormGroup;
  subscription: Subscription;
  newCustomer = false;
  existingPMs: any;
  existingPMSelected = null;
  existingPMSelectedUserId = null;
  notifyAllHaulers = true;
  listOfHaulers: any;
  haulerToNotify: any;
  newPMFirm = null;
  states = STATES;
  showErrorMessage = false;
  showServerErrorMessage = false;
  showIncompleteProfileMessage = false;
  disableButton = false;
  processingRequest = false;
  imagesUploaded = false;
  images: Set<File> = new Set();
  imageList: File[] = [];
  fileUploadStatus: Subscription;
  confirmationNumber;

  constructor(private fb: FormBuilder, private router: Router, private orderService: OrderService,
    private authService: AuthService, private profileService: ProfileService, private confirmationService: ConfirmationService) {
      // this if statement is needed due to a unintended behavior in Auth0
      // when a user logs in isAuthenticated() returns false until all auth0 code executes
      // authService.readyStatic checks to see if auth0 has finsihed executing, if false, subscribe and wait till complete
      if (this.authService.readyStatic) {
        if (this.authService.isAuthenticated()) {
          this.userRole = this.authService.getRole();
          this.setControls();
          this.checkPermission();
        }
      } else {
        this.authService.ready.subscribe(ready => {
          this.userRole = this.authService.getRole();
          this.setControls();
          this.checkPermission();
        });
      }
  }

  ngOnInit() {
    this.profileService.getListOfPMs().subscribe(users => {
      if (users.length > 0) {
        this.existingPMs = users
      }
    }, err => {
      this.existingPMs = [];
    });
    this.profileService.getListOfHaulers().subscribe(result => {
      if (result.success) {
        this.listOfHaulers = result.data
      }
    }, err => {
      this.listOfHaulers = [];
    });
  }

  setControls() {
    if (this.userRole === 'super-admin') {
      this.isSuperAdmin = true;
      this.isDriver = false;
      this.isPropertyManager = false;
      this.isAuthorized = true;
    } else if (this.userRole === 'driver') {
      this.isSuperAdmin = false;
      this.isDriver = true;
      this.isPropertyManager = false;
      this.isAuthorized = false;
    } else if (this.userRole === 'property-manager') {
      this.isSuperAdmin = false;
      this.isDriver = false;
      this.isPropertyManager = true;
      this.isAuthorized = false;
    } else {
      this.router.navigate( ['/unauthorized'] );
    }
  }

  checkPermission() {
    if (!this.isAuthorized) {
      this.router.navigate(['/unauthorized']);
    } else {
      this.getLoggedInProfile();
    }
  }

  getLoggedInProfile() {
    if (this.profileService.localUserProfile) {
      this.userProfile = this.profileService.localUserProfile;
      this.isProfileComplete();
      this.initializeRequestForm();
    } else {
      this.subscription = this.profileService.getProfile().subscribe(profile => {
        this.userProfile = profile;
        this.isProfileComplete();
        this.initializeRequestForm();
      }, err => {
        console.log(err)
      });
    }
  }

  isProfileComplete() {
    if (this.userProfile  && this.userProfile.user_metadata.profile_complete) {
      this.disableButton = false;
      this.showIncompleteProfileMessage = false;
    } else {
      this.disableButton = true;
      this.showIncompleteProfileMessage = true;
    }
  }

  initializeRequestForm() {
    this.requestForm = this.fb.group({
      customerInfo: this.fb.group({
        name: this.fb.group({
          fName: ['', Validators.required],
          lName: ['', Validators.required]
        }),
        pm_user_id: [''],
        contactInfo: this.fb.group({
          phone: ['', Validators.required],
          email: ['', Validators.required],
        }),
        address: this.fb.group({
          address_line1: [ '', Validators.required ],
          address_line2: '',
          city: [ '', Validators.required ],
          state: [ '', Validators.required ],
          postal_code: [ '', Validators.required ],
        }),
      }),
      property_management_firm: ['', Validators.required],
      request_description: ['', Validators.required],
      request_images: this.images
    })
  }

  checkSubmission() {
    if (!this.newCustomer) {
      this.mergeRequestInfo();
    } else {
      this.checkFormStatus();
    }
  }

  checkFormStatus() {
    if (this.requestForm.valid) {
      this.showErrorMessage = false;
      this.checkImages();
    } else {
      this.showErrorMessage = true;
      this.processingRequest = false;
    }
  }

  checkImages() {
    if (!this.imagesUploaded) {
      this.proceedWithoutImages()
    } else {
      this.processRequest();
    }
  }

  mergeRequestInfo() {
    this.processingRequest = true;
    // get PM that was selected
    this.existingPMSelected = this.getPMSelected();
    // check to see if there is a user_id for the PM selected
    if (this.existingPMSelected && this.existingPMSelected.user_id) {
      // fetch rest of the information needed for the PM request
      this.profileService.getPMProfile(this.existingPMSelected.user_id).subscribe(pmInfo => {
        // get info from pm profile
        let fname, lname, pmFirm, phone, email, pmUserId;
        fname = pmInfo.user_metadata.first_name;
        lname = pmInfo.user_metadata.last_name;
        email = pmInfo.email;
        phone = pmInfo.user_metadata.phone_number;
        pmFirm = pmInfo.user_metadata.company;
        pmUserId = pmInfo.user_id;
        this.existingPMSelectedUserId = pmUserId;
        // map missing info from form to pm profile
        this.requestForm.get('customerInfo.name')['controls'].fName.setValue(fname);
        this.requestForm.get('customerInfo.name')['controls'].lName.setValue(lname);
        this.requestForm.get('customerInfo')['controls'].pm_user_id.setValue(pmUserId);
        this.requestForm.get('customerInfo.contactInfo')['controls'].email.setValue(email);
        this.requestForm.get('customerInfo.contactInfo')['controls'].phone.setValue(phone);
        this.requestForm.controls.property_management_firm.setValue(pmFirm);
        this.checkFormStatus();
      });
    } else {
      // TODO: show error
    }
  }

  getPMSelected() {
    return  this.existingPMs.find(x => x.name === this.existingPMSelected);
  }

  proceedWithoutImages() {
    this.confirmationService.confirm({
      message: 'No Images have been uploaded. Proceed without images?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // process request without images
        this.processRequest()
      },
      reject: () => {
        // do not do anything
      }
    })
  }

  processRequest() {
    this.requestForm.controls.request_images.setValue(this.imageList.length);
    let haulerInfo;
    if (!this.notifyAllHaulers && this.haulerToNotify) {
      haulerInfo = this.haulerToNotify;
   } 
    this.orderService.createPMRequest(this.requestForm.value, this.existingPMSelectedUserId, haulerInfo).subscribe(response => {
      console.log(response);
      if (response.request) {
        this.confirmationNumber = response.request.confirmation_number;
        this.orderService.setCachedOrder(response.request);
        // this.authService.retrieveData();
        this.processImageUpload(response.request);
      }
    }, err => {
      console.error(err);
      this.processingRequest = false;
      this.showErrorMessage = true
    });
  }

  processImageUpload(request) {
    if (this.images.size > 0) {
      this.initializeImageUploadStatus();
      this.orderService.uploadImages(request.order_id, this.images, 'pm');
    } else {
      this.processingRequest = false;
      this.router.navigateByUrl('/pm-requests/view/' + request.confirmation_number);
    }

  }

  initializeImageUploadStatus() {
    this.orderService.imageUploadComplete.subscribe(complete => {
      if (complete) {
        this.processingRequest = false;

        this.router.navigateByUrl('/pm-requests/view/' + this.confirmationNumber);
      }
    }, err => {
      console.log(err);
      this.router.navigateByUrl('/pm-requests/view/' + this.confirmationNumber);
    });
  }

  uploadImages(images, form) {
    const files: { [key: string]: File } = images;
    if (images.length > 0) {
      for (const key in files) {
        if ( !isNaN(parseInt(key, 10)) ) {
          this.images.add(files[key]);
          this.imageList.push(files[key]);
        }
      }
      this.imagesUploaded = true;
      form.clear();
    }
  }

}
