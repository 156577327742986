export const environment = {
  production: false,
  backendurl: 'https://stagingbe.haulshare.co',
  frontendurl: 'https://demo.haulshare.co',
  customerSite: 'http://www.haulshare.co',
  auth0_clientID: 'IWhHHaIX8Hdi83ohB8haE1q8nPS0ak6I',
  auth0_domain: 'haulshare.auth0.com',
  auth0_audience: 'https://haulshare.auth0.com/userinfo',
  stripeKey: 'pk_test_HCNg9E2dP6S6sCOqwk6oVZb3',
  vapid_public_key: 'BBdq2rrJAC0RYio_BiH_PUg9jF_LTzN4bup7sm42PoUZcVaEt3LBf_DBwBdexF6y7LJMsy5Pz3GTtAA9S5Se-Mc'
};
