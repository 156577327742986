export const ROUTES_ADMIN = [
    {
        key: 'dashboard',
        path: 'dashboard',
        title: 'Dashboard',
        icon: 'dashboard',
        class: '',
        menuItem: true
    },
    {
        key: 'all-customer-orders',
        path: 'orders',
        title: 'Customer Orders',
        icon: 'trending_up',
        class: '',
        menuItem: true
    },
    {
        key: 'all-pm-requests',
        path: 'pm-requests',
        title: 'PM Requests',
        icon: 'maps_home_work',
        class: '',
        menuItem: true
    },
    {
        key: 'order-detail',
        path: 'orders/view',
        title: 'View Order Details',
        menuItem: false
    },
    {
        key: 'pm-request-detail',
        path: 'pm-request/view',
        title: 'View PM Request Details',
        menuItem: false
    },
    {
        key: 'custom-transaction',
        path: 'customTransaction',
        title: 'Create Transaction',
        icon: 'attach_money',
        menuItem: true
    },
    {
        key: 'profile',
        path: 'user-profile',
        title: 'My Profile',
        icon: 'person', class: '',
        menuItem: true
    },
]
export const ROUTES_DRIVER = [
    {
        key: 'dashboard',
        path: 'dashboard',
        title: 'Dashboard',
        icon: 'dashboard',
        class: '',
        menuItem: true
    },
    {
        key: 'all-orders',
        path: 'orders',
        title: 'View Available Jobs',
        icon: 'trending_up',
        class: '',
        menuItem: true
    },
    {
        key: 'my-orders',
        path: 'my-orders',
        title: 'My Jobs',
        icon: 'check',
        class: '',
        menuItem: true
    },
    {
        key: 'pm-request-detail',
        path: 'pm-request/view',
        title: 'View PM Request Details',
        menuItem: false
    },
    {
        key: 'profile',
        path: 'user-profile',
        title: 'My Profile',
        icon: 'person', class: '',
        menuItem: true
    },
]

export const ROUTES_PM = [
    {
        key: 'dashboard',
        path: 'dashboard',
        title: 'Dashboard',
        icon: 'dashboard',
        class: '',
        menuItem: true
    },
    {
        key: 'my-orders',
        path: 'my-orders',
        title: 'View My Requests',
        icon: 'trending_up',
        class: '',
        menuItem: true
    },
    {
        key: 'pm-request-detail',
        path: 'pm-request/view',
        title: 'View PM Request Details',
        menuItem: false
    },
    {
        key: 'submit-request',
        path: 'submitRequest',
        title: 'Submit Request',
        icon: 'attach_money',
        menuItem: true
    },
    {
        key: 'profile',
        path: 'user-profile',
        title: 'My Profile',
        icon: 'person', class: '',
        menuItem: true
    },
]

export const ROUTES_UNAUTHORIZED = []

