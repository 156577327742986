import { Component, OnInit, ViewChild } from '@angular/core';

import { ListUtilsService } from 'app/services/list-utils/list-utils.service';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth/auth.service';

import { OrderService } from 'app/services/order/order.service';

// Materianl Table Imports
import { MatPaginator, MatSort, MatTableDataSource }  from '@angular/material';
@Component({
  selector: 'app-view-completed-orders',
  templateUrl: './view-completed-orders.component.html',
  styleUrls: ['./view-completed-orders.component.scss']
})

export class ViewCompletedOrdersComponent implements OnInit {
  // Control Variables
  userRole = '';
  isSuperAdmin = false;
  isDriver = false;
  isPropertyManager = false;

  // data variables
  orders: any [] = [];
  totalOrders: number;


  showOrderLoadError = false;

  // Mat Table Stuff
  ordersDataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['confrimation_number', 'email', 'date_created', 'dateScheduled', 'status_message'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private authService: AuthService, 
    private orderService: OrderService,private listUtils: ListUtilsService) {
      if (this.authService.readyStatic) {
        if (this.authService.isAuthenticated()) {
          this.userRole = this.authService.getRole();
          this.setControls();
          this.retrieveData();
      } else {
          this.authService.ready.subscribe(ready => {
            this.userRole = this.authService.getRole();
            this.setControls();
          });
        }
      }
  }

  ngOnInit() {
  }

  setControls() {
    if (this.userRole === 'super-admin') {
      this.isSuperAdmin = true;
      this.isDriver = false;
      this.isPropertyManager = false;
    } else if (this.userRole === 'driver') {
      this.isSuperAdmin = false;
      this.isDriver = true;
      this.isPropertyManager = false;
    } else if (this.userRole === 'property-manager') {
      this.isSuperAdmin = false;
      this.isDriver = false;
      this.isPropertyManager = true;
    } else {
      this.router.navigate( ['/dashboard'] );
    }

    if(this.isSuperAdmin) {
      this.router.navigateByUrl('/unauthorized');
    }
  }

  // this funcion retrieves data based on the user role and user id
  // if the logged in user is a driver, fetch all orders that have haulpro_assigned === loggedIn user_id'
  //     for super admins, this page will not be available
  // if the logged in user is a property-manager, they will not be able to view this page - they will be redirected to /unauthorized page
  // if the logged in user is a driver, this page will display all unclaimed orders and requests
  // TODO: Implement pagination functinality
  retrieveData()  {
    const [orderType, orderStatus, haulpro_assigned] = this.determineFetchParameters();
    this.orderService.retrieveOrders(orderType, orderStatus, haulpro_assigned).subscribe( response => {
      if (response.success) {
        this.orders = response.data.Items;
        this.orders = this.listUtils.formatOrders(this.orders);
        this.formatOrders(this.orders);
        this.setUpMatTable();
        this.showOrderLoadError = false;
      } else {
        response.message ? console.error(response.message) : console.error('Error:\n' + response);
        this.showOrderLoadError = true;
      }
    }, error =>{
      error ? console.error(error) : console.error('An error has occured');
      this.showOrderLoadError = true;
    })
  }

  navigateToOrderDetail(order) {
    if (order.type === 'prepaid_customer_order') {
      this.router.navigateByUrl('/orders/view/' + order.confirmation_number);
    } else if (order.type === 'pm_request') {
      this.router.navigateByUrl('/pm-requests/view/' + order.confirmation_number);
    }
  }

  private determineFetchParameters() {
    let orderType, orderStatus, haulproAssigned: string;
    if (this.isDriver) {
      [orderType, orderStatus, haulproAssigned] = ['', '', this.authService.getUserId()];
    } else if (this.isPropertyManager) {
      [orderType, orderStatus, haulproAssigned] = ['pm_request', '', '']
    }

    return [orderType, orderStatus, haulproAssigned];
  }

  private setUpMatTable() {
    this.ordersDataSource = new MatTableDataSource(this.orders);
    this.ordersDataSource.paginator = this.paginator;
    this.ordersDataSource.sort = this.sort;
  }

   // private function to filter on MatTable object
   private applyFilter(filterValue: string) {
    this.ordersDataSource.filter = filterValue.trim().toLocaleLowerCase();

    if (this.ordersDataSource.paginator) {
      this.ordersDataSource.paginator.firstPage();
    }
  } 

  private formatOrders(orders) {
    for (const order of orders) {
      order.confirmation_number = String(order.confirmation_number);
    }
  }

}
