import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()

export class HttpService {
    private token;
    private httpOptions;

    constructor(private http: HttpClient) {
        this.token = localStorage.getItem('id_token')
        
    }

    get(url: string): Observable<any> {
        return this.http.get(url, this.httpOptions);
    }

    post(url: string, params: any): Observable<any> {
        return this.http.post(url, params, this.httpOptions);
    }

    postImage(url: string, params: any, httpOptions): Observable<any> {
        return this.http.post(url, params, httpOptions);
    }

    setHeaders(token) {
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        }
    } 
}
