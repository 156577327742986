import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Observable, Subject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';


import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';


@Injectable()
export class OrderService {
  backendUrl = environment.backendurl;
  orders: any[];
  orderCount: number;
  userId = '';
  cachedOrder: any;

  PM_ROLE = 'property-manager';
  HAULER_ROLE = 'driver';

  imageUploadComplete = new Subject();
  cachedImages: any [] = [];
  requestImages = new Subject();
  pmImages = new Subject();
  cachedPMImages: any[] = [];
  hpImages = new Subject();
  cachedHPImages: any[] = [];
  otherImages = new Subject();
  cachedOtherImages: any[] = [];
  
  pagination = {
    LastEvaluatedKey: null
  }

  constructor(private http: HttpService, private authService: AuthService) { }

  // only called by super-admin
  // this function is used to process prepaid_customer_orders
  processPayment(stripeDetails: any, invoiceDetails: any, haulerToNotify?) {
    const params = {
      stripe_details: stripeDetails,
      invoice_details: invoiceDetails,
      hauler_to_notify: haulerToNotify
    }

    return this.http.post(this.backendUrl + '/customOrder/new', params);
  }

  // only called by super-admin
  // this function is used to create a new PM request
  createPMRequest(requestDetails, pm_userId?, haulerToNotify?): Observable<any> {
    const pmRequest = {
      requestDetails: requestDetails,
      submitted_by: pm_userId ? pm_userId : this.authService.getUserId(),
      hauler_to_notify: haulerToNotify
    }

    return this.http.post(this.backendUrl + '/pm/request/new', pmRequest);
  }

  // function to upload images to S3 bucket
  public uploadImages(orderId, images: Set<File>, submitter): { [key: string]: { progress: Observable<number> } }  {
    // let requestImages = null;
    const status: { [key: string]: { progress: Observable<number> } } = {};
    if (images) {
      let counter = 0;
      const imageCount = images.size;

      images.forEach(file => {
        const formData: FormData = new FormData();
        formData.append('submitter', submitter);
        formData.append('order_id', orderId);
        formData.append('image', file, file.name);

        const progress = new Subject<number>();
        const token = localStorage.getItem('id_token');
        const headers = new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          // 'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token
        });
        const options = {
          headers: headers,
          reportProgress: true
        }

        this.http.postImage(this.backendUrl + '/pm/request/upload/' + orderId, formData, options).subscribe(result => {
          if(result.success) {
            this.imageUploadComplete.next(true);
          } else {
            alert('Error Uploading Image. Order has been submitted.');
          }
        }, err => {
          alert('Error Uploading Image:' + err);
          console.log(err);
        });

        status[file.name] = {
          progress: progress.asObservable()
        }
      })
    }

    return status;
  }

  // Gets count of orders that match orderType and orderStatus
  // orderType: pm_request | prepaid_customer_order | '' (Empty string means both pm_request and prepaid_customer_order)
  // orderStatus: 'unclaimed' | '' (Empty string means all status')
  // haulpro_assigned: 'auth0|_______' | '' (Empty string means unassigned order)
  retrieveNumOfOrders(orderType?, orderStatus?, haulpro_assigned?) {
    const params = {
      filters: {
        order_type: orderType ? orderType : '',
        orderStatus: orderStatus ? orderStatus : '',
        haulpro_assigned: haulpro_assigned ? haulpro_assigned : ''
      }
    }
    return this.http.post(this.backendUrl + '/orders/count', params);
  }

  getOrders() {
    return this.orders;
  }

  getOrderDetail(confirmation_number) {
    return this.http.get(this.backendUrl + '/orders/details/' + confirmation_number);
  }

  // only called by a driver
  // this will retrive all orders matching orderStatus or haulpro_assigned filters
  retrieveOrdersDriver(orderStatus, haulproAssigned) {
    const params = {
      filters: {
        orderStatus: orderStatus,
        haulpro_assigned: haulproAssigned
      }
    }
    return this.http.post(this.backendUrl + '/orders', params);
  }

  // only called by an super-admin role
  // this will retrieve all orders of all order status'
  retrieveOrders(orderType?, orderStatus?, haulproAssigned?) {
    const params = {
      filters: {
        order_type: orderType ? orderType : '',
        orderStatus: orderStatus ? orderStatus : '',
        haulpro_assigned: haulproAssigned ? haulproAssigned : '',
        pagination: this.pagination
      }
    }
    return this.http.post(this.backendUrl + '/orders/', params);
  }

  // helper function to set LastEvaluatedKey - needed for pagination
  setLastEvaluatedKey(lastEvaluatedKey) {
    this.pagination.LastEvaluatedKey = lastEvaluatedKey;
  }


  updateOrderStatusToOpen(orderId, dateCreated, userId) {
    const params = {
      order_id: orderId,
      date_created: dateCreated,
      user_id: userId,
    }
    return this.http.post(this.backendUrl + '/orders/update-order/open', JSON.stringify(params));
  }

  updateOrderStatusToScheduled(orderId, dateCreated, newStatus, userId, haulproAssigned, eta, customer_email, haulerEarning) {
    const params = {
      order_id: orderId,
      date_created: dateCreated,
      status: newStatus,
      user_id: userId,
      haulpro_assigned: haulproAssigned,
      eta: eta,
      customer_email: customer_email,
      hauler_earning: haulerEarning
    }
    return this.http.post(this.backendUrl + '/orders/update-order/schedule', JSON.stringify(params));
  }

  public updateOrderStatusToDriverComplete(orderId, dateCreated, status, userId, haulproAssigned, customer_email, haulproNotes) {
    const timeCompleted = new Date();
    const params = {
      order_id: orderId,
      date_created: dateCreated,
      status: status,
      user_id: userId,
      haulpro_assigned: haulproAssigned,
      haulpro_notes: haulproNotes,
      time_completed: timeCompleted,
      customer_email: customer_email
    }

    return this.http.post(this.backendUrl + '/orders/update-order/driver-complete', JSON.stringify(params));

  }

  public updateRequestStatusToIncomplete(orderId, dateCreated, chargeInfo) {
    const params = {
      order_id: orderId,
      date_created: dateCreated,
      earnings_info: chargeInfo
    }

    return this.http.post(this.backendUrl + '/orders/update-order/incomplete', JSON.stringify(params));

  }

  updateOrderStatusToComplete(orderId, dateCreated, userId, haulproAssigned, eta, customer_email, haulproNotes) {
    const timeCompleted = new Date();
    const params = {
      order_id: orderId,
      date_created: dateCreated,
      user_id: userId,
      haulpro_assigned: haulproAssigned,
      haulpro_notes: haulproNotes,
      eta: eta,
      customer_email: customer_email,
      time_completed: timeCompleted
    }
    return this.http.post(this.backendUrl + '/orders/update-order/complete', JSON.stringify(params));
  }

  updateOrderStatusToCancelled(orderId, dateCreated, userId) {
    const params = {
      order_id: orderId,
      date_created: dateCreated,
      user_id: userId,
    }
    return this.http.post(this.backendUrl + '/orders/update-order/cancel', JSON.stringify(params));
  }

  setCachedOrder(newOrder) {
    this.cachedOrder = newOrder;
    this.retrieveImagesToCache();
  }

  private retrieveImagesToCache() {
    this.http.get(this.backendUrl + '/pm/request/images/' + this.cachedOrder.order_id).subscribe(response => {
      if (response.data && (response.data.Count > 0)) {
        this.cachedImages = response.data.Items;
        this.seperateImages();
      }
    }, err => {
      console.log(err);
      this.cachedImages = null;
    });
  }

  private seperateImages() {
    // reset cached images first
    this.cachedHPImages = [];
    this.cachedPMImages = [];
    this.cachedOtherImages = [];
    this.cachedImages.forEach(image => {
      image.file = this.backendUrl + '/pm/request/image/' + image.filename;
      if (image.submitted_by_role === this.HAULER_ROLE) {
        this.cachedHPImages.push(image);
      } else if (image.submitted_by_role === this.PM_ROLE) {
        this.cachedPMImages.push(image);
      } else {
        this.cachedOtherImages.push(image);
      }
    });
    this.hpImages.next(this.cachedHPImages);
    this.pmImages.next(this.cachedPMImages);
    this.otherImages.next(this.cachedOtherImages);
  }

  // function to notify haulers of order
  public notifyHaulers(haulerInfo) {
    const params = {
      hauler_to_notify: haulerInfo
    }
    return this.http.post(this.backendUrl + '/orders/notify', params);
  }

}
