import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// environment variables
import { environment } from '../../../environments/environment'

// Auth0 stuff
import * as auth0 from 'auth0-js';
import { HttpService } from '../http/http.service';
import { Subject } from 'rxjs';

// JWT Decode
import jwt_decode from 'jwt-decode';

// User Model

@Injectable()

export class AuthService {
    private backend = environment.backendurl;
    public finishedDataFetch = new Subject();
    public finishedDataFetchLocal = false;
    public permissionSet = new Subject();
    public permissionSetLocal: any;
    public userId: string;
    public userRole: string;
    public isSuperAdmin = false;
    public isDriver = false;
    public isPropertManager = false;
    public readyStatic = false;
    public ready = new Subject();

    auth0 = new auth0.WebAuth({
        clientID: environment.auth0_clientID,
        domain: environment.auth0_domain,
        responseType: 'token id_token',
        audience: environment.auth0_audience,
        redirectUri: environment.frontendurl + '/dashboard',
        scope: 'openid profile',
        allowedConnections: [ 'Username-Password-Authentication' ],
        allowShowPassword: true
    });

    constructor(private router: Router, private http: HttpService) {
        this.setUserId(this.getUserToken());
        this.setRole(this.getUserToken());
        if(this.userId && this.userRole && this.isAuthenticated()) {
            // successfully logged in
            this.saveTokenToHttpHeaders(this.getUserToken());
            this.doneLoading();
        } else {
            // route to unauthorized page
            this.router.navigateByUrl('/');
        }
    }

    public login(): void {
        this.auth0.authorize();
    }


    public logout(): void {
        // Remove tokens and expiry time from localStorage
        localStorage.removeItem( 'access_token' );
        localStorage.removeItem( 'id_token' );
        localStorage.removeItem( 'expires_at');
        localStorage.removeItem( 'user_id' );

        // Go back to unauthenticated page
        window.location.href = environment.customerSite;
    }

    public isAuthenticated(): boolean {
        // Check whether the current time is past the Access Token's expiry time
        // const token  = localStorage.getItem('token')
        const expiresAt = JSON.parse( localStorage.getItem('expires_at') );
        return new Date().getTime() < expiresAt;
    }

    public handleAuthentication(): void {
        this.auth0.parseHash( ( err, authResult ) => {
            if ( authResult && authResult.accessToken && authResult.idToken ) {
                window.location.hash = '';
                this.setSession( authResult );
                this.router.navigate( ['/dashboard'] );
                // set profile in profile_service
            } else if ( err ) {
                window.location.href = environment.frontendurl;
                console.log(err);
            }

        });
    }

    private setSession(authResult): void {
        //  Set the time that the Access token will expire at
        const expiresAt = JSON.stringify( ( authResult.expiresIn * 1000 ) + new Date().getTime() );
        localStorage.setItem( 'access_token' , authResult.accessToken );
        localStorage.setItem( 'id_token' , authResult.idToken );
        localStorage.setItem( 'expires_at' , expiresAt );
        localStorage.setItem( 'user_id', authResult.idTokenPayload.sub );
        this.saveTokenToHttpHeaders(authResult.idToken);
        this.setUserId(authResult.idToken);
        this.setRole(authResult.idToken);
        if(this.userId && this.userRole) {
            // successfully logged in
            this.doneLoading();
        } else {
            // route to unauthorized page
            this.router.navigateByUrl('/unauthorized');
        }
    }
    
    // private function to add the jwt token to all http headers
    private saveTokenToHttpHeaders(token) {
        this.http.setHeaders(token);
    }

    // Private function that sets the userId of the user.
    // function will parse a JWT token set userId
    private setUserId(token) {
        if (token) {
            const decodedToken: any = this.decodeToken(token);
            if (decodedToken['sub'] && decodedToken['sub'] !== '') {
                this.userId = decodedToken['sub'];
            } else {
                // malformed jwt token
                this.userId = null;
            }
        } else {
            this.userId = null;
        }
    }

    // Private function that sets the role of the user. Role of the user will determine permission levels
    // function will parse a JWT to find and set role
    private setRole(token) {
        let role: string;
        if (token) {
            const decodedToken: any = this.decodeToken(token);
            if (decodedToken["https://app.haulshare.co/roles"] 
                && decodedToken["https://app.haulshare.co/roles"].length === 1) {
                    role = decodedToken["https://app.haulshare.co/roles"][0];
                } else {
                    role = ''
                }
                
            if (role === 'HaulShare') {
                this.userRole = 'super-admin'
                this.isSuperAdmin = true;
                this.isDriver = false;
                this.isPropertManager = false;
            } else if (role === 'HaulPro') {
                this.userRole = 'driver'
                this.isSuperAdmin = false;
                this.isDriver = true;
                this.isPropertManager = false;
            } else if (role === 'HaulSharePM') {
                this.userRole = 'property-manager'
                this.isSuperAdmin = false;
                this.isDriver = false;
                this.isPropertManager = true;
            } else {
                this.userRole = null;
                this.isSuperAdmin = false;
                this.isDriver = false;
                this.isPropertManager = false;
            }
        } else {
            this.userRole = null;
            this.isSuperAdmin = false;
            this.isDriver = false;
            this.isPropertManager = false;
        }
    }

    // returns stored userId of logged in user
    public getUserId() {
        return this.userId;
    }

    // returns stored role of logged in user
    public getRole() {
        return this.userRole;
    }

    // returns stored jwt token of logged in user
    public getUserToken() {
        return localStorage.getItem('id_token');
    }

    public checkPermission(permission) {
        let returnVal;

        returnVal = this.permissionSetLocal.permissions[permission];
        if ( !returnVal || returnVal === '') {
            returnVal = null;
        }

        return returnVal;
    }


    private doneLoading() {
        // this.finishedDataFetchLocal = true;
        // this.finishedDataFetch.next(this.finishedDataFetchLocal);
        this.readyStatic = true;
        this.ready.next(true);
    }

    private decodeToken(token) {
        try {
            return jwt_decode(token);
        } catch( Error ) {
            return null;
        }
    }

}
