import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth/auth.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  constructor(private authSerivce: AuthService) { }

  ngOnInit() {
  }
}
