import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderService } from 'app/services/order/order.service';

import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth/auth.service';
import { ListUtilsService } from 'app/services/list-utils/list-utils.service';

// Materianl Table Imports
import { MatPaginator, MatSort, MatTableDataSource }  from '@angular/material';

@Component({
  selector: 'app-view-orders',
  templateUrl: './view-orders.component.html',
  styleUrls: ['./view-orders.component.scss']
})
export class ViewOrdersComponent implements OnInit  {
  // Control Variables
  userRole = '';
  isSuperAdmin = false;
  isDriver = false;
  isPropertyManager = false;
  
  // data variables
  orders: any [] = [];
  totalOrders: number;
  
  
  showOrderLoadError = false;

  // Mat Table Stuff
  ordersDataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['confrimation_number', 'email', 'date_created', 'dateScheduled', 'status_message'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private authService: AuthService, private orderService: OrderService, private listUtils: ListUtilsService) {
    // this if statement is needed due to a unintended behavior in Auth0
    // when a user logs in isAuthenticated() returns false until all auth0 code executes
    // authService.readyStatic checks to see if auth0 has finsihed executing, if false, subscribe and wait till complete
    if (this.authService.readyStatic) {
      if (this.authService.isAuthenticated()) {
        this.userRole = this.authService.getRole();
        this.setControls();
        this.retrieveData();
    } else {
        this.authService.ready.subscribe(ready => {
          this.userRole = this.authService.getRole();
          this.setControls();
        });
      }
    }
  }

  ngOnInit() { }


  setControls() {
    if (this.userRole === 'super-admin') {
      this.isSuperAdmin = true;
      this.isDriver = false;
      this.isPropertyManager = false;
    } else if (this.userRole === 'driver') {
      this.isSuperAdmin = false;
      this.isDriver = true;
      this.isPropertyManager = false;
    } else if (this.userRole === 'property-manager') {
      this.isSuperAdmin = false;
      this.isDriver = false;
      this.isPropertyManager = true;
    } else {
      this.router.navigate( ['/dashboard'] );
    }
    
  }

  // this funcion retrieves data based on the user role and user id
  // if the logged in user is a super-admin, fetch all order of all status'
  //     for super admins, this page will only fetch prepaid_customer_orders, pm-requests will not be retrived for this view
  // if the logged in user is a property-manager, they will not be able to view this page - they will be redirected to /unauthorized page
  // if the logged in user is a driver, this page will display all unclaimed orders and requests
  // TODO: Implement pagination functinality
  public retrieveData() {
    if (this.isSuperAdmin && !this.isDriver && !this.isPropertyManager) {
      this.fetchOrdersAdmin();
    } else if (!this.isSuperAdmin && this.isDriver && !this.isPropertyManager) {
      this.fetchOrdersAndRequestsDriver();
    } else if (!this.isSuperAdmin && !this.isDriver && this.isPropertyManager) {
      this.router.navigateByUrl('/unauthorized');
    }
  }

  // this funtion retrieves orders for super-admin role
  private fetchOrdersAdmin() {
    const ordersToFetch = 'prepaid_customer_order';
    this.orderService.retrieveOrders(ordersToFetch).subscribe( response => {
      if (response.success) {
        this.orders = response.data.Items;
        this.orders = this.listUtils.formatOrders(this.orders);
        this.formatOrders(this.orders);
        this.setUpMatTable();
        this.showOrderLoadError = false;
      } else {
        response.message ? console.error(response.message) : console.error('Error:\n' + response);
        this.showOrderLoadError = true;
      }
    }, error =>{
      error ? console.error(error) : console.error('An error has occured');
      this.showOrderLoadError = true;
    })

  }

  // this function retrieves orders and requests for drivers
  fetchOrdersAndRequestsDriver() {
    const ordersToFetch = 'unclaimed';
    this.orderService.retrieveOrders(null, ordersToFetch, '' ).subscribe( ordersResponse => {
      // TO DO : fetch Requests and combine into one orders list to appear in the table
      // In the meantime, table will only show unclaimed orders
      if (ordersResponse.success) {
        this.orders = ordersResponse.data.Items;
        this.orders = this.listUtils.formatOrders(this.orders);
        this.formatOrders(this.orders);
        this.setUpMatTable();
        this.showOrderLoadError = false;
      } else {
        ordersResponse.message ? console.error(ordersResponse.message) : console.error('Error:\n' + ordersResponse);
        this.showOrderLoadError = true;
      }
    }, err => {
      console.error(err);
      this.showOrderLoadError = true;

    });

  }

  navigateToOrderDetail(order) {
    if (order.type === 'prepaid_customer_order') {
      this.router.navigateByUrl('/orders/view/' + order.confirmation_number);
    } else if (order.type === 'pm_request') {
      this.router.navigateByUrl('/pm-requests/view/' + order.confirmation_number);
    }
  }

  private setUpMatTable() {
    this.ordersDataSource = new MatTableDataSource(this.orders);
    this.ordersDataSource.paginator = this.paginator;
    this.ordersDataSource.sort = this.sort;
  }

  // private function to filter on MatTable object
  private applyFilter(filterValue: string) {
    this.ordersDataSource.filter = filterValue.trim().toLocaleLowerCase();

    if (this.ordersDataSource.paginator) {
      this.ordersDataSource.paginator.firstPage();
    }
  }

  private formatOrders(orders) {
    for (const order of orders) {
      order.confirmation_number = String(order.confirmation_number);
    }
  }
}
