import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { STATES } from '../../models/states.list';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

// Services
import { AuthService } from 'app/services/auth/auth.service';
import { ProfileService } from 'app/services/profile/profile.service';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { OrderService } from 'app/services/order/order.service';

@Component({
  selector: 'app-submit-request',
  templateUrl: './submit-request.component.html',
  styleUrls: ['./submit-request.component.scss']
})
export class SubmitRequestComponent implements OnInit {
  permissionNeeded = 'request-submit';
  // control variables
  userRole: string;
  isSuperAdmin = false;
  isDriver = false;
  isPropertyManager = false;
  isAuthorized = false;

  // data variables
  userProfile: any;
  requestForm: FormGroup;
  subscription: Subscription;
  states = STATES;
  showErrorMessage = false;
  showServerErrorMessage = false;
  showIncompleteProfileMessage = false;
  disableButton = false;
  processingRequest = false;
  imagesUploaded = false;
  images: Set<File> = new Set();
  imageList: File[] = [];
  fileUploadStatus: Subscription;
  confirmationNumber;

  // This class is responsible for controlling the page that allows Property Managers to submit PM
  constructor(private fb: FormBuilder, private router: Router,
    private authService: AuthService, private profileService: ProfileService, private orderService: OrderService,
    private confirmationService: ConfirmationService) {
    // this if statement is needed due to a unintended behavior in Auth0
    // when a user logs in isAuthenticated() returns false until all auth0 code executes
    // authService.readyStatic checks to see if auth0 has finsihed executing, if false, subscribe and wait till complete
    if (this.authService.readyStatic) {
      if (this.authService.isAuthenticated()) {
        this.userRole = this.authService.getRole();
        this.setControls();
        this.checkPermission();
      }
    } else {
      this.authService.ready.subscribe(ready => {
        this.userRole = this.authService.getRole();
        this.setControls();
        this.checkPermission();
      });
    }
  }

  ngOnInit() {
  }

  setControls() {
    if (this.userRole === 'super-admin') {
      this.isSuperAdmin = true;
      this.isDriver = false;
      this.isPropertyManager = false;
    } else if (this.userRole === 'driver') {
      this.isSuperAdmin = false;
      this.isDriver = true;
      this.isPropertyManager = false;
    } else if (this.userRole === 'property-manager') {
      this.isSuperAdmin = false;
      this.isDriver = false;
      this.isPropertyManager = true;
      this.isAuthorized = true;
    } else {
      this.router.navigate( ['/dashboard'] );
    }
  }

  checkPermission() {
    if(this.isPropertyManager) {
      this.getLoggedInProfile();
    } else {
      this.router.navigateByUrl('/unauthorized');
    }
  }

  getLoggedInProfile() {
    if (this.profileService.localUserProfile) {
      this.userProfile = this.profileService.localUserProfile;
      this.isProfileComplete();
      this.initializeRequestForm();
    } else {
      this.subscription = this.profileService.getProfile().subscribe(profile => {
        this.userProfile = profile;
        this.isProfileComplete();
        this.initializeRequestForm();
      }, err => {
        console.log(err)
      });
    }
  }

  isProfileComplete() {
    if (this.userProfile  && this.userProfile.user_metadata.profile_complete) {
      this.disableButton = false;
      this.showIncompleteProfileMessage = false;
    } else {
      this.disableButton = true;
      this.showIncompleteProfileMessage = true;
    }
  }

  initializeRequestForm() {
    this.requestForm = this.fb.group({
      customerInfo: this.fb.group({
        name: this.fb.group({
          fName: this.userProfile.user_metadata.first_name,
          lName: this.userProfile.user_metadata.last_name
        }),
        contactInfo: this.fb.group({
          phone: this.userProfile.user_metadata.phone_number,
          email: this.userProfile.email,
        }),
        address: this.fb.group({
          address_line1: [ '', Validators.required ],
          address_line2: '',
          city: [ '', Validators.required ],
          state: [ '', Validators.required ],
          postal_code: [ '', Validators.required ],
        }),
      }),
      property_management_firm: this.userProfile.user_metadata.company,
      request_description: ['', Validators.required],
      request_images: this.images
    })
  }

  initializeImageUploadStatus() {
    this.orderService.imageUploadComplete.subscribe(complete => {
      if (complete) {
        this.processingRequest = false;

        this.router.navigateByUrl('/pm-requests/view/' + this.confirmationNumber);
      }
    });
  }

  checkSubmission() {
    if (this.requestForm.valid) {
      // console.log('Form Submitted');
      this.showErrorMessage = false;
      if (!this.imagesUploaded) {
        this.proceedWithoutImages()
      } else {
        this.processRequest();
      }
    } else {
      this.showErrorMessage = true;
    }
  }

  proceedWithoutImages() {
    this.confirmationService.confirm({
      message: 'No Images have been uploaded. Proceed without images?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // process request without images
        this.processRequest()
      },
      reject: () => {
        // do not do anything
      }
    })
  }

  processRequest() {
    this.processingRequest = true;
    this.requestForm.controls.request_images.setValue(this.imageList.length);
    this.orderService.createPMRequest(this.requestForm.value).subscribe(response => {
      console.log(response);
      if (response.request) {
        this.confirmationNumber = response.request.confirmation_number;
        this.orderService.setCachedOrder(response.request);
        // this.authService.retrieveData();
        if (this.images.size > 0) {
          this.initializeImageUploadStatus();
          this.orderService.uploadImages(response.request.order_id, this.images, 'pm');
        } else {
          this.processingRequest = false;
          this.router.navigateByUrl('/pm-requests/view/' + response.request.confirmation_number);

        }
      }
    }, err => {
      console.error(err);
      this.showErrorMessage = true;
      this.processingRequest = false;
    });
  }

  uploadImages(images, form) {
    const files: { [key: string]: File } = images;
    if (images.length > 0) {
      for (const key in files) {
        if ( !isNaN(parseInt(key, 10)) ) {
          this.images.add(files[key]);
          this.imageList.push(files[key]);
        }
      }
      this.imagesUploaded = true;
      form.clear();
    }
  }
}
