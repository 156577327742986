import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth/auth.service';

@Component({
  selector: 'app-custom-transaction',
  templateUrl: './custom-transaction.component.html',
  styleUrls: ['./custom-transaction.component.scss']
})
export class CustomTransactionComponent implements OnInit {
  // control variables
  isSuperAdmin = false;
  isPropertyManager = false;
  isDriver = false;
  isAuthorized = false;
  userRole: string;

  constructor(private router: Router, private authService: AuthService) {
    // this if statement is needed due to a unintended behavior in Auth0
    // when a user logs in isAuthenticated() returns false until all auth0 code executes
    // authService.readyStatic checks to see if auth0 has finsihed executing, if false, subscribe and wait till complete
    if (this.authService.readyStatic) {
      if (this.authService.isAuthenticated()) {
        this.userRole = this.authService.getRole();
        this.setControls();
        this.checkPermission();
      }
    } else {
      this.authService.ready.subscribe(ready => {
        this.userRole = this.authService.getRole();
        this.setControls();
        this.checkPermission();
      });
    }
  }

  ngOnInit() {

  }

  setControls() {
    if (this.userRole === 'super-admin') {
      this.isSuperAdmin = true;
      this.isDriver = false;
      this.isPropertyManager = false;
      this.isAuthorized = true;
    } else if (this.userRole === 'driver') {
      this.isSuperAdmin = false;
      this.isDriver = true;
      this.isPropertyManager = false;
      this.isAuthorized = false;
    } else if (this.userRole === 'property-manager') {
      this.isSuperAdmin = false;
      this.isDriver = false;
      this.isPropertyManager = true;
      this.isAuthorized = false;
    } else {
      this.router.navigate( ['/unauthorized'] );
    }
  }
  checkPermission() {
    if (!this.isAuthorized) {
      this.router.navigate(['/unauthorized']);
    }
  }

}
