import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES_ADMIN, ROUTES_DRIVER, ROUTES_PM, ROUTES_UNAUTHORIZED } from '../../models/routes';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { AuthService } from '../../services/auth/auth.service';
import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { ProfileService } from 'app/services/profile/profile.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    private listTitles: any[] = [];
    location: Location;
    private toggleButton: any;
    private sidebarVisible: boolean;
    private routes = ROUTES_ADMIN;
    private key = null;
    private role;

    constructor(private router: Router, location: Location,  private element: ElementRef,
        private authService: AuthService, private profileService: ProfileService) {
            // this.setControls();
            // this.setNavBarItems();
            this.location = location;
            this.sidebarVisible = false;
            this.router.events.subscribe( (data) => {
                if (data instanceof RoutesRecognized) {
                    this.key = data.state.root.firstChild.params.key;
                }
            });
    }

    ngOnInit() {
        for (const route of this.routes) {
            if (route.title && !(route.title === '')) {
              this.listTitles.push(route);
            }
        }
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function() {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    getTitle() {
        let returnVal = 'Welcome'
        let titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice( 2 );
        }
        if (this.key) {
            const optionalRoute = this.listTitles.find(i => i.key === this.key);
            if (optionalRoute) {
                returnVal = optionalRoute.title;
            }
        } else {
            for (const title of this.listTitles) {
                if (titlee.indexOf(title.path) > 0) {
                    returnVal = title.title;
                }
            }

        }
        return returnVal;
    }
}
